import { useEffect } from "react";
import { Timestamp } from "firebase/firestore";

import useQuestions from "./useQuestions";
import { ActivityTypesProps } from "../Activities";
import { NewResponse, Response } from "../../../models/Response";
import useResponseService from "../../../services/useResponseService";

interface FillBlankActivityProps extends ActivityTypesProps {
  isTeacherMode: boolean;
}

export default function FillBlankActivity({
  onBack,
  onNext,
  activity,
  isTeacherMode,
}: FillBlankActivityProps) {
  const { activityAnswers, activityQuestions } = useQuestions(activity);

  const { updateResponse, createResponse } = useResponseService();

  useEffect(() => {
    async function onSaveAnswer(answerIdx: number) {
      let updatedResponse: Response;
      let activityAnswer = activityAnswers[answerIdx];

      activityAnswer.timestamp = Timestamp.fromDate(new Date());
      if (activityAnswer.id) {
        updatedResponse = await updateResponse(
          activityAnswer.id,
          activityAnswer as Response
        );
      } else {
        updatedResponse = await createResponse(activityAnswer as NewResponse);
      }

      activityAnswers.splice(answerIdx, 1, updatedResponse);
    }

    function onBlurEvent(ev: FocusEvent) {
      const input = ev.target as HTMLInputElement;
      const questionIdx = activityQuestions.findIndex(
        (question) => question.id === input.id
      );
      if (questionIdx > -1) {
        const questionAnswer = activityAnswers[questionIdx];
        questionAnswer.answer = input.value;
        onSaveAnswer(questionIdx);
      }
    }

    activityQuestions.forEach((question, idx) => {
      const input = document.getElementById(question.id) as HTMLInputElement;

      if (input) {
        input.addEventListener("blur", onBlurEvent);

        const questionAnswer = activityAnswers[idx];
        input.value = questionAnswer.answer || "";
      }
    });

    return () => {
      activityQuestions.forEach((question) => {
        const el = document.getElementById(question.id);
        if (el) el.removeEventListener("blur", onBlurEvent);
      });
    };
  }, [createResponse, updateResponse, activityAnswers, activityQuestions]);

  useEffect(() => {
    function attachTeacherInfo() {
      activityQuestions.forEach((question) => {
        const el = document.getElementById(question.id);
        if (el) {
          const svg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          svg.setAttribute("width", "34");
          svg.setAttribute("height", "34");
          svg.setAttribute("viewBox", "0 0 34 34");
          svg.setAttribute("fill", "none");

          const path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
          );
          path.setAttribute("fill", "#FFB155");
          path.setAttribute(
            "d",
            "M17.0163 0.962158C18.2651 0.962234 19.4711 1.41713 20.4089 2.24179L20.6561 2.47459L21.7768 3.59527C22.0844 3.90083 22.486 4.09372 22.9168 4.14276L23.1335 4.15561H24.7391C26.0512 4.15553 27.3136 4.65746 28.2674 5.55845C29.2212 6.45943 29.7942 7.69121 29.8688 9.00117L29.8768 9.29339V10.8989C29.8768 11.3324 30.0246 11.7547 30.2911 12.0919L30.4356 12.2524L31.5547 13.3731C32.4822 14.2954 33.0231 15.5368 33.0669 16.8441C33.1108 18.1514 32.6543 19.4263 31.7907 20.4086L31.5579 20.6559L30.4372 21.7766C30.1316 22.0841 29.9387 22.4858 29.8897 22.9165L29.8768 23.1333V24.7388C29.8769 26.0509 29.375 27.3133 28.474 28.2671C27.573 29.221 26.3412 29.794 25.0313 29.8686L24.7391 29.8766H23.1335C22.7006 29.8767 22.2804 30.0227 21.9406 30.2908L21.78 30.4353L20.6594 31.5544C19.737 32.482 18.4957 33.0228 17.1884 33.0667C15.881 33.1105 14.6062 32.6541 13.6238 31.7904L13.3766 31.5576L12.2559 30.4369C11.9483 30.1314 11.5467 29.9385 11.1159 29.8895L10.8992 29.8766H9.29362C7.98154 29.8767 6.71914 29.3748 5.76531 28.4738C4.81148 27.5728 4.2385 26.341 4.16388 25.031L4.15585 24.7388V23.1333C4.15571 22.7004 4.00979 22.2802 3.74161 21.9403L3.59711 21.7798L2.47804 20.6591C1.55048 19.7368 1.00964 18.4954 0.965782 17.1881C0.921926 15.8808 1.37836 14.606 2.24203 13.6236L2.47483 13.3763L3.59551 12.2556C3.90106 11.9481 4.09396 11.5464 4.143 11.1157L4.15585 10.8989V9.29339L4.16388 9.00117C4.23557 7.74153 4.76825 6.5523 5.66039 5.66015C6.55254 4.76801 7.74177 4.23533 9.00141 4.16364L9.29362 4.15561H10.8992C11.3321 4.15547 11.7523 4.00955 12.0921 3.74137L12.2527 3.59687L13.3733 2.4778C13.8507 1.99756 14.4183 1.61643 15.0435 1.35632C15.6687 1.09621 16.3392 0.962256 17.0163 0.962158Z"
          );
          svg.appendChild(path);

          const scoreDivElement = document.createElement("div");
          scoreDivElement.style.top = "50%";
          scoreDivElement.style.right = "-40px";
          scoreDivElement.style.position = "absolute";
          scoreDivElement.style.display = "inline-block";
          scoreDivElement.style.transform = "translate(-50%, -50%)";

          const scoreElement = document.createElement("span");
          scoreElement.classList.add("score");
          scoreElement.innerText = `${question.defaultPoints}` || "10";
          scoreElement.style.top = "50%";
          scoreElement.style.left = "50%";
          scoreElement.style.color = "white";
          scoreElement.style.position = "absolute";
          scoreElement.style.display = "inline-block";
          scoreElement.style.transform = "translate(-50%, -50%)";
          //el.insertAdjacentElement("afterend", scoreElement);
          //el.insertAdjacentElement("afterend", svg);

          scoreDivElement.appendChild(svg);
          scoreDivElement.appendChild(scoreElement);

          const correctAnswerElement = document.createElement("span");
          correctAnswerElement.classList.add("correct-answer");
          correctAnswerElement.innerText = `${question.correctAnswer}` || "";
          //correctAnswerElement.style.color = "rgb(22 163 74)";
          //el.insertAdjacentElement("afterend", correctAnswerElement);

          const divElement = document.createElement("div");
          divElement.style.marginRight = "25px";
          divElement.style.position = "relative";
          divElement.style.display = "inline-block";
          divElement.appendChild(correctAnswerElement);
          divElement.appendChild(scoreDivElement);

          el.insertAdjacentElement("afterend", divElement);
        }
      });
    }

    function removeTeacherInfo() {
      const correctAnswerElements = Array.from(
        document.getElementsByClassName("correct-answer")
      );
      const scoreElements = Array.from(
        document.getElementsByClassName("score")
      );
      scoreElements.forEach((el) => el.remove());
      correctAnswerElements.forEach((el) => el.remove());
    }

    if (isTeacherMode) {
      attachTeacherInfo();
    } else {
      removeTeacherInfo();
    }
  }, [isTeacherMode, activityQuestions]);

  return (
    <div className="h-full overflow-y-scroll">
      <div className="relative">
        <img className="w-full h-auto" alt="" src={activity.heroImageUrl} />
      </div>
      <div className="grid w-full grid-cols-1 gap-6 mt-28 md:mt-8 xl:mt-14">
        <div dangerouslySetInnerHTML={{ __html: activity.contentHtml }}></div>
        <div className="self-center bg-white rounded-xl w-fit px-7 py-6 justify-self-center">
          <button
            className="py-4 px-16 bg-[#FFB155] rounded-full text-base font-['Semibold'] w-full"
            onClick={onNext}
          >
            {activity.nextButtonTitle}
          </button>
        </div>
      </div>
    </div>
  );
}
