import { FirestoreDataConverter } from "firebase/firestore";
import { Teacher } from "../../models/Teacher";

export const teacherConverter: FirestoreDataConverter<Teacher> = {
  toFirestore: (teacher) => ({
    firstname: teacher.firstname,
    lastname: teacher.lastname,
    classSectionsIds: teacher.classSectionsIds,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      firstname: data.firstname,
      lastname: data.lastname,
      classSectionsIds: data.classSectionsIds,
    };
  },
};
