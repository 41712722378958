import {
  collection,
  where,
  getDocs,
  query,
  doc,
  getDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { omit } from "lodash";
import { useCallback } from "react";

import { db } from "../db/firebase";
import { Question } from "../models/Question";
import { Activity, NewActivity } from "../models/Activity";
import { questionConverter } from "../db/converters/question";
import { activityConverter } from "../db/converters/activity";

class ActivityFoundError extends Error {
  constructor() {
    super("Activity not found.");
  }
}

export default function useActivityService() {
  const getActivity = useCallback(
    async (activityId: string): Promise<Activity> => {
      const activityDocRef = doc(db, `activities`, activityId).withConverter(
        activityConverter
      );

      const activityDoc = await getDoc(activityDocRef);

      if (activityDoc.data() === undefined) throw new ActivityFoundError();

      return activityDoc.data()!;
    },
    []
  );

  const getActivities = useCallback(async (): Promise<Activity[]> => {
    const activitiesCollectionRef = query(
      collection(db, "activities")
    ).withConverter(activityConverter);

    const activityCollection = await getDocs(activitiesCollectionRef);

    return activityCollection.docs.map((doc) => doc.data());
  }, []);

  const getActivityQuestions = useCallback(
    async (activityId: string): Promise<Question[]> => {
      const questionsCollectionRef = query(
        collection(db, "questions"),
        where("activityId", "==", activityId)
      ).withConverter(questionConverter);

      const questionsCollection = await getDocs(questionsCollectionRef);

      return questionsCollection.docs.map((doc) => doc.data());
    },
    []
  );

  async function createActivity(activity: NewActivity): Promise<Activity> {
    const newTopic = await addDoc(
      collection(db, `activities`).withConverter(activityConverter),
      activity
    );

    return {
      ...activity,
      id: newTopic.id,
    };
  }

  async function updateActivity(
    activityId: string,
    activity: Activity
  ): Promise<Activity> {
    await updateDoc(doc(db, "activities", activityId), {
      ...omit(activity, "id"),
    });

    return activity;
  }

  return {
    getActivity,
    getActivities,
    createActivity,
    updateActivity,
    getActivityQuestions,
  };
}
