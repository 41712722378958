import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSidebar } from "../../Home";
import useQuestions from "./useQuestions";
import { Timestamp } from "firebase/firestore";
import { ActivityTypesProps } from "../Activities";
import { TopicSection } from "../../../models/Topic";
import useTopicService from "../../../services/useTopicService";
import { NewResponse, Response } from "../../../models/Response";
import useResponseService from "../../../services/useResponseService";

interface BlogTableQuestionActivityProps extends ActivityTypesProps {
  tableColumns: string[];
  isTeacherMode: boolean;
}

export default function BlogTableQuestionActivity({
  onBack,
  onNext,
  activity,
  tableColumns,
}: BlogTableQuestionActivityProps) {
  const { isCollapse, setIsCollapse } = useSidebar();

  const { getTopicSection } = useTopicService();
  const { updateResponse, createResponse } = useResponseService();
  const { topicSectionId } = useParams<{
    topicSectionId: string;
    classSectionId: string;
  }>();

  const { activityAnswers, activityQuestions, setActivityAnswers } =
    useQuestions(activity);

  const [topicSection, setTopicSection] = useState<TopicSection>();

  useEffect(() => {
    init();
    async function init() {
      const topicSection = await getTopicSection(topicSectionId!);
      setTopicSection(topicSection);
    }
  }, [topicSectionId, getTopicSection]);

  useEffect(() => {
    setIsCollapse(true);

    return () => {
      setIsCollapse(false);
    };
  }, [isCollapse, setIsCollapse]);

  async function onSaveAnswer(answerIdx: number) {
    let updatedResponse: Response;
    let activityAnswer = activityAnswers[answerIdx];
    console.log(activityAnswer);

    activityAnswer.timestamp = Timestamp.fromDate(new Date());
    if (activityAnswer.id) {
      updatedResponse = await updateResponse(
        activityAnswer.id,
        activityAnswer as Response
      );
    } else {
      updatedResponse = await createResponse(activityAnswer as NewResponse);
    }

    activityAnswers.splice(answerIdx, 1, updatedResponse);
    setActivityAnswers([...activityAnswers]);
  }

  function hasDueDateExpire(): boolean {
    const today = moment();
    const dueDate = moment(topicSection?.dueDate.toDate());

    return today.isAfter(dueDate);
  }

  function renderQuestionInput(
    activityQuestion: any,
    questionIdx: number,
    columnIdx: number
  ) {
    switch (activityQuestion.questionType) {
      case "simple-text":
        return (
          <input
            placeholder="Your answer"
            value={activityAnswers[questionIdx].answer[columnIdx]}
            onChange={(e) => {
              let changedAnswer: any =
                activityAnswers[questionIdx].answer[columnIdx];
              changedAnswer = e.target.value;
              activityAnswers[questionIdx].answer.splice(
                columnIdx,
                1,
                changedAnswer
              );
              setActivityAnswers([...activityAnswers]);
            }}
            disabled={hasDueDateExpire()}
            onBlur={() => onSaveAnswer(questionIdx)}
            className="mt-7 bg-[#EFEFF3] h-12 py-4 text-sm text-[#9595A8] rounded-md w-full border-none"
          />
        );
      case "text-area":
        return (
          <textarea
            rows={8}
            placeholder="Your answer"
            value={activityAnswers[questionIdx].answer[columnIdx]}
            onChange={(e) => {
              let changedAnswer: any =
                activityAnswers[questionIdx].answer[columnIdx];
              changedAnswer = e.target.value;
              activityAnswers[questionIdx].answer.splice(
                columnIdx,
                1,
                changedAnswer
              );
              setActivityAnswers([...activityAnswers]);
            }}
            disabled={hasDueDateExpire()}
            onBlur={() => onSaveAnswer(questionIdx)}
            className="mt-7 md:mt-3 xl:mt-7 bg-[#EFEFF3] py-4 text-sm text-[#9595A8] rounded-md w-full border-none"
          />
        );
      case "checkbox":
        return (
          <div className="space-y-5">
            {activityQuestion.options!.map((option: any) => (
              <div key={option.value} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={option.value}
                    type="checkbox"
                    checked={
                      activityAnswers[questionIdx].answer[columnIdx]
                        ?.split(",")
                        .includes(option.value) || false
                    }
                    aria-describedby="comments-description"
                    onChange={(e) => {
                      let changedAnswer: any =
                        activityAnswers[questionIdx].answer[columnIdx];
                      const currentValues = changedAnswer
                        ? changedAnswer.split(",")
                        : [];
                      if (e.target.checked) {
                        currentValues.push(e.target.id);
                      } else {
                        const optionIdx = currentValues.findIndex(
                          (value: any) => value === e.target.id
                        );
                        currentValues.splice(optionIdx, 1);
                      }

                      changedAnswer = currentValues.join(",");
                      activityAnswers[questionIdx].answer.splice(
                        columnIdx,
                        1,
                        changedAnswer
                      );
                      setActivityAnswers([...activityAnswers]);
                      onSaveAnswer(questionIdx);
                    }}
                    disabled={hasDueDateExpire()}
                    className="h-4 w-4 rounded border-gray-300 text-[#FFB155] focus:ring-[#FFB155]"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={option.value}
                    className="font-medium text-gray-900"
                  >
                    {option.label}
                  </label>{" "}
                </div>
              </div>
            ))}
          </div>
        );
      case "radio-button":
        return (
          <div className="space-y-4 sm:flex sm:flex-col sm:items-start sm:space-y-0">
            {activityQuestion.options!.map((option: any) => {
              return (
                <div key={option.value} className="flex items-center">
                  <input
                    id={`${option.value}-${columnIdx}`}
                    type="radio"
                    name={`options-${columnIdx}`}
                    value={activityAnswers[questionIdx].answer[columnIdx]}
                    checked={
                      activityAnswers[questionIdx].answer[columnIdx] ===
                      option.value
                    }
                    onChange={(e) => {
                      let changedAnswer: any =
                        activityAnswers[questionIdx].answer[columnIdx];

                      if (e.target.checked) {
                        changedAnswer = option.value;
                      } else {
                        changedAnswer = "";
                      }
                      activityAnswers[questionIdx].answer.splice(
                        columnIdx,
                        1,
                        changedAnswer
                      );
                      setActivityAnswers([...activityAnswers]);
                      onSaveAnswer(questionIdx);
                    }}
                    disabled={hasDueDateExpire()}
                    className="h-4 w-4 border-gray-300 text-[#FFB155] focus:ring-[#FFB155]"
                  />
                  <label
                    htmlFor={option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        );
      case "dropdown":
        return (
          <select
            value={activityAnswers[questionIdx].answer[columnIdx]}
            onChange={(e) => {
              let changedAnswer: any =
                activityAnswers[questionIdx].answer[columnIdx];
              changedAnswer = e.target.value;
              activityAnswers[questionIdx].answer.splice(
                columnIdx,
                1,
                changedAnswer
              );
              setActivityAnswers([...activityAnswers]);
            }}
            disabled={hasDueDateExpire()}
            onBlur={() => onSaveAnswer(questionIdx)}
          >
            <option value={-1}>Select your answer</option>
            {activityQuestion.options.map((option: any) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        );
    }
  }

  return (
    <div className="h-full flex flex-col">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-[Semibold] text-gray-900 sm:pl-6"
            />
            {tableColumns.map((col, idx) => (
              <th
                key={idx}
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-[Semibold] text-gray-900 sm:pl-6"
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {activityQuestions.map((activityQuestion, questionIdx) => (
            <tr key={activityQuestion.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-[Regular] text-gray-900 sm:pl-6">
                {activityQuestion.title}
              </td>
              {tableColumns.map((column, colIdx) => (
                <td
                  key={colIdx}
                  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-[Regular] text-gray-900 sm:pl-6"
                >
                  {renderQuestionInput(activityQuestion, questionIdx, colIdx)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between mt-auto">
        <button
          className="font-['Semibold'] text-base border border-[#E0E0EC] rounded-full px-10 py-4 leading-5"
          onClick={onBack}
        >
          Back
        </button>
        <button
          onClick={() => onNext()}
          className="font-['Semibold'] text-base bg-[#FFB155] rounded-full px-10 py-4 leading-5"
        >
          Next
        </button>
      </div>
    </div>
  );
}
