import { ActivityTypesProps } from "../Activities";

interface BlogActivityProps extends ActivityTypesProps {}

export default function BlogActivity({
  onBack,
  onNext,
  activity,
}: BlogActivityProps) {
  return (
    <div className="h-full overflow-y-scroll">
      <div className="relative">
        <img className="w-full h-auto" alt="" src={activity.heroImageUrl} />
      </div>
      <div className="grid w-full grid-cols-1 gap-6 mt-28 md:mt-8 xl:mt-14 ">
        <div dangerouslySetInnerHTML={{ __html: activity.contentHtml }}></div>
        <div className="self-center bg-white rounded-xl w-fit px-7 py-6 justify-self-center">
          <button
            className="py-4 px-16 bg-[#FFB155] rounded-full text-base font-['Semibold'] w-full"
            onClick={onNext}
          >
            {activity.nextButtonTitle}
          </button>
        </div>
      </div>
    </div>
  );
}
