import { Link } from "react-router-dom";

import OpenBookIcon from "../Icons/OpenBookIcon";
import { ClassSection } from "../../models/SchoolClass";

const topicSectionImg = require("./topic-section.png");
interface ClassSectionsGridProps {
  parentNavigation?: string;
  classSections: ClassSection[];
}

export default function ClassSectionsGrid({
  classSections,
  parentNavigation = "",
}: ClassSectionsGridProps) {
  return (
    <div>
      <ul className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
        {classSections.map((classSection) => (
          <Link
            key={classSection.id}
            to={`${parentNavigation}/class-sections/${classSection.id}/topics`}
            className="rounded-xl overflow-hidden"
          >
            <img className="w-full object-cover" alt="" src={topicSectionImg} />
            <div className="pt-3 pb-16 px-6 bg-[#E8CBFF] rounded-b-xl relative">
              <div className="flex items-center gap-3">
                <div className="w-7 h-7 bg-[#A785C9] rounded-3xl flex items-center justify-center">
                  <OpenBookIcon />
                </div>
              </div>
              <h3 className="text-base mt-4 font-semibold">
                {classSection.name}
              </h3>
            </div>
          </Link>
        ))}
      </ul>
    </div>
  );
}
