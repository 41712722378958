import { omit } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/20/solid";

import ActivityModal from "./ActivityModal";
import useActivities from "./useActivities";
import { Topic, TopicSection } from "../../../models/Topic";
import ContentView from "../../../components/ContentView";
import useTopicService from "../../../services/useTopicService";
import { Activity, ActivityType, NewActivity } from "../../../models/Activity";
import useActivityService from "../../../services/useActivityService";

export default function Activities() {
  const navigate = useNavigate();
  const { getTopics, getAllTopicSections } = useTopicService();
  const { createFillBlankActivityQuestions } = useActivities();
  const { getActivities, getActivity, createActivity, updateActivity } =
    useActivityService();

  const [openModal, setOpenModal] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activity, setActivity] = useState<Activity | undefined>();
  const [topicSections, setTopicSections] = useState<TopicSection[]>([]);

  useEffect(() => {
    init();
    async function init() {
      const topics = await getTopics();
      const activities = await getActivities();
      const topicSections = await getAllTopicSections();

      setTopics(topics);
      setActivities(
        activities.sort((a, b) => {
          if (a.name! < b.name!) return -1;
          if (a.name! > b.name!) return 1;
          return 0;
        })
      );
      setTopicSections(topicSections);
    }
  }, [getTopics, getActivities, getAllTopicSections]);

  function onCreateActivity() {
    setActivity(undefined);
    setOpenModal(true);
  }

  async function onEditActivity(activityId: string) {
    const activity = await getActivity(activityId);
    setActivity(activity);
    setOpenModal(true);
  }

  async function onDuplicateActivity(activityId: string) {
    const activity = await getActivity(activityId);
    setActivity({
      ...(omit(activity, "id") as Activity),
    });
    setOpenModal(true);
  }

  async function onSaveActivity(
    name: string,
    order: number,
    activityType: ActivityType,
    subtitle: string,
    topicSectionId: string,
    heroImageUrl: string,
    nextButtonTitle: string,
    teachersGuide: string,
    contentHtml: string,
    tableColumns: string[]
  ) {
    if (activity && activity.id) {
      const updatedActivity: Activity = {
        ...activity,
        name,
        order,
        activityType,
        subtitle,
        topicSectionId,
        heroImageUrl,
        nextButtonTitle,
        teachersGuide,
        contentHtml,
        tableColumns,
      };
      await updateActivity(activity.id, updatedActivity);
    } else {
      const createdActivity: NewActivity = {
        name,
        order,
        activityType,
        subtitle,
        topicSectionId,
        heroImageUrl,
        nextButtonTitle,
        teachersGuide,
        contentHtml,
        tableColumns,
      };

      const newActivity = await createActivity(createdActivity);

      if (activityType === "fill-blanks") {
        const htmlString = await createFillBlankActivityQuestions(newActivity);
        newActivity.contentHtml = htmlString;
        await updateActivity(newActivity.id, newActivity);
      }
    }

    const activities = await getActivities();
    setActivities(activities);
    setActivity(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Activities"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateActivity}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new activity
        </span>
      }
    >
      <>
        <ul className="mt-3 p-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
          {activities.map((activity) => (
            <li
              key={activity.id}
              className="col-span-1 flex rounded-md shadow-sm"
            >
              <div className="flex flex-1 items-center justify-between rounded-r-md rounded-l-md border-l border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <span className="font-medium text-gray-900 hover:text-gray-600">
                    {activity.name}
                  </span>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={() => onEditActivity(activity.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={() => onDuplicateActivity(activity.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <DocumentDuplicateIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {openModal && (
          <ActivityModal
            topics={topics}
            open={openModal}
            activity={activity}
            topicSections={topicSections}
            onClose={() => {
              setOpenModal(false);
              setActivity(undefined);
            }}
            onSubmit={onSaveActivity}
          />
        )}
      </>
    </ContentView>
  );
}
