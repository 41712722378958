import { useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { User } from "../../models/User";
import useQuestions from "./useQuestions";
import { TopicSection } from "../../models/Topic";
import { NewScore, Score } from "../../models/Score";
import ContentView from "../../components/ContentView";
import useUserService from "../../services/useUserService";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import useTopicService from "../../services/useTopicService";
import useScoreService from "../../services/useScoreService";
import useClassService from "../../services/useClassService";
import useQuestionService from "../../services/useQuestionService";

export default function StudentGrading() {
  const navigate = useNavigate();
  const { topicSectionId, studentId } = useParams<{
    studentId: string;
    topicSectionId: string;
  }>();

  const {
    getGradingQuestions,
    getUserQuestionScores,
    getUserQuestionResponse,
  } = useQuestionService();
  const { getUser } = useUserService();
  const { getClassSection } = useClassService();
  const { createScore, updateScore } = useScoreService();
  const { getTopic, getTopicSection, getTopicSectionActivities } =
    useTopicService();

  const [student, setStudent] = useState<User>();
  const [schoolClassId, setSchoolClassId] = useState<string>("");
  const [topicSection, setTopicSection] = useState<TopicSection>();

  const {
    activities,
    activityScores,
    activityAnswers,
    activityQuestions,
    setActivityScores,
  } = useQuestions(studentId!, topicSectionId!);

  useEffect(() => {
    init();

    async function init() {
      const user = await getUser(studentId!);
      const topicSection = await getTopicSection(topicSectionId!);
      const topic = await getTopic(topicSection.topicId);
      const classSection = await getClassSection(topic.classSectionId);

      setStudent(user);
      setTopicSection(topicSection);
      setSchoolClassId(classSection.schoolClassId);
    }
  }, [
    getUser,
    getTopic,
    studentId,
    topicSectionId,
    getClassSection,
    getTopicSection,
    getGradingQuestions,
    getUserQuestionScores,
    getUserQuestionResponse,
    getTopicSectionActivities,
  ]);

  async function onSaveScores() {
    const scores = [];
    for (let i = 0; i < activityScores.length; i++) {
      let updatedScore: Score;
      let activityScore = activityScores[i];
      activityScore.datetime = Timestamp.fromDate(new Date());
      if (activityScore.id) {
        updatedScore = await updateScore(
          studentId!,
          activityScore.id,
          activityScore as Score
        );
      } else {
        updatedScore = await createScore(studentId!, activityScore as NewScore);
      }

      scores.push(updatedScore);
    }

    setActivityScores(scores);
    navigate(-1);
  }

  function getQuestionIndex(questionId: string) {
    return activityQuestions.findIndex(
      (question) => question.id === questionId
    );
  }

  function showGradingWarning(
    questionScore?: number,
    questionPoints?: Record<string, number>
  ) {
    const points = questionPoints
      ? questionPoints[schoolClassId]
        ? questionPoints[schoolClassId]
        : 0
      : 0;

    if (!points || !questionScore) return false;

    if (questionScore > points) return true;

    return false;
  }

  return (
    <ContentView
      title={`Grading - ${topicSection?.name || ""} - ${
        student?.firstname || ""
      } ${student?.lastname || ""}`}
      onBack={() => navigate(-1)}
    >
      <div className="flex flex-col gap-6 h-full overflow-y-scroll">
        {activities.map((activity) => (
          <div key={activity.id}>
            <h3 className="font-[Bold] text-lg text-[#9595A8] bg-[#EFEFF3] p-1">
              {activity.name}
            </h3>
            {activityQuestions
              .filter((question) => question.activityId === activity.id)
              .map((question) => {
                const idx = getQuestionIndex(question.id);

                return (
                  <div
                    key={question.id}
                    className="rounded-lg p-4 bg-white shadow"
                  >
                    <h3 className="font-[Bold] text-lg">
                      {question.title}{" "}
                      {question.points
                        ? question.points[schoolClassId]
                          ? `(${question.points[schoolClassId]} points)`
                          : question.defaultPoints
                        : question.defaultPoints}
                    </h3>
                    <div className="grid grid-cols-2">
                      <div>
                        <span className="font-[Semibold] text-[#9595A8] text-sm">
                          Student Response:{" "}
                        </span>
                        {activityAnswers[idx]?.answer || ""}
                      </div>
                      <div>
                        <span className="font-[Semibold] text-[#9595A8] text-sm">
                          Teachers Guide:{" "}
                        </span>
                        {question.correctAnswer && (
                          <span className="flex items-center gap-2 mt-1 mb-7 text-green-600">
                            <CheckCircleIcon className="h-4 w-4 text-green-600" />
                            <span>{question.correctAnswer}</span>
                          </span>
                        )}
                        {showGradingWarning(
                          activityScores[idx].score,
                          question.points
                        ) && (
                          <span className="text-sm font-[Semibold] text-orange-500">
                            This is a higher number than the max points for this
                            question
                          </span>
                        )}
                        <input
                          placeholder="Your score"
                          value={
                            isNaN(activityScores[idx].score!)
                              ? ""
                              : activityScores[idx].score
                          }
                          onChange={(e) => {
                            let changedScore = activityScores[idx];
                            changedScore.score = parseInt(e.target.value);
                            activityScores.splice(idx, 1, changedScore);
                            setActivityScores([...activityScores]);
                          }}
                          onBlur={(e) => {
                            const currentScore = activityScores[idx].score;
                            const questionScore = question.points
                              ? question.points[schoolClassId]
                              : 0;

                            if (questionScore !== 0 && currentScore) {
                              if (currentScore > questionScore) {
                              }
                            }
                          }}
                          className="mt-2 bg-[#EFEFF3] h-12 py-4 text-sm text-[#9595A8] rounded-md w-full border-none"
                        />
                        <textarea
                          rows={4}
                          placeholder="Your comment"
                          value={activityScores[idx].comment}
                          onChange={(e) => {
                            let changedScore = activityScores[idx];
                            changedScore.comment = e.target.value;
                            activityScores.splice(idx, 1, changedScore);
                            setActivityScores([...activityScores]);
                          }}
                          className="mt-7 bg-[#EFEFF3] py-4 text-sm text-[#9595A8] rounded-md w-full border-none"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ))}
        <button
          className="font-['Semibold'] text-base bg-[#FFB155] rounded-full px-10 py-4 leading-5 self-end"
          onClick={onSaveScores}
        >
          Submit Grades
        </button>
      </div>
    </ContentView>
  );
}
