import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { User } from "../../models/User";
import StudentsTable from "./StudentsTable";
import ContentView from "../../components/ContentView";
import useTeacherService from "../../services/useTeacherService";

export default function StudentsRoaster() {
  const navigate = useNavigate();
  const { getTeacherStudents } = useTeacherService();

  const [students, setStudents] = useState<User[]>([]);

  useEffect(() => {
    init();

    async function init() {
      const students = await getTeacherStudents();
      setStudents(students);
    }
  }, [getTeacherStudents]);

  return (
    <ContentView title="Students Roster" onBack={() => navigate(-1)}>
      <StudentsTable students={students} />
    </ContentView>
  );
}
