import { useState, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface ResetPasswordModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (newPassword: string) => void;
}

export default function ResetPasswordModal({
  open,
  onClose,
  onSubmit,
}: ResetPasswordModalProps) {
  const [newPassword, setNewPassword] = useState("");
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-white rounded-2xl py-10 px-16 text-left shadow-xl transition-all">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:mx-auto bg-white rounded-2xl">
                  <h3 className="text-3xl font-['Bold'] text-center mb-10">
                    Reset Password
                  </h3>
                </div>
                <form
                  className="space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(newPassword);
                  }}
                >
                  <div>
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-['Semibold'] leading-4 text-[#9595A8]"
                    >
                      New Password
                    </label>
                    <div className="mt-2">
                      <input
                        required
                        id="newPassword"
                        name="newPassword"
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
                      />
                    </div>
                  </div>
                  <div className="w-full h-px bg-[#E0E0EC]" />

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="flex justify-center rounded-full bg-[#FFB155] px-28 py-3 text-base font-['Semibold'] leading-6 text-black shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
