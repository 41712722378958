import { FirestoreDataConverter } from "firebase/firestore";

import {
  TopicSectionSubmission,
  Topic,
  TopicSection,
} from "../../models/Topic";

export const topicConverter: FirestoreDataConverter<Topic> = {
  toFirestore: (topic) => ({
    name: topic.name,
    headerImageUrl: topic.headerImageUrl,
    classSectionId: topic.classSectionId,
    order: topic.order,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      classSectionId: data.classSectionId,
      headerImageUrl: data.headerImageUrl,
      order: data.order,
    };
  },
};

export const topicSectionConverter: FirestoreDataConverter<TopicSection> = {
  toFirestore: (topicSection) => ({
    name: topicSection.name,
    topicId: topicSection.topicId,
    order: topicSection.order,
    published: topicSection.published,
    headerImageUrl: topicSection.headerImageUrl,
    dueDate: topicSection.dueDate,
    submissionDeadline: topicSection.submissionDeadline,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      order: data.order,
      topicId: data.topicId,
      published: data.published,
      dueDate: data.dueDate,
      submissionDeadline: data.submissionDeadline,
      headerImageUrl: data.headerImageUrl,
    };
  },
};

export const topicSectionSubmissionConverter: FirestoreDataConverter<TopicSectionSubmission> =
  {
    toFirestore: (submittedTopicSection) => ({
      topicSectionId: submittedTopicSection.topicSectionId,
      datetime: submittedTopicSection.datetime,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        topicSectionId: data.topicSectionId,
        datetime: data.datetime,
      };
    },
  };
