import { Link } from "react-router-dom";

import { Topic } from "../../models/Topic";
import OpenBookIcon from "../../components/Icons/OpenBookIcon";

interface TopicGridItemProps {
  topic: Topic;
}

export default function TopicGridItem({ topic }: TopicGridItemProps) {
  return (
    <Link to={`${topic.id}`} className="rounded-xl">
      <img className="w-full" alt="" src={topic.headerImageUrl} />
      <div className="pt-3 pb-16 px-6 bg-[#E8CBFF] rounded-b-xl relative">
        <div className="flex items-center gap-3">
          <div className="w-7 h-7 bg-[#A785C9] rounded-3xl flex items-center justify-center">
            <OpenBookIcon />
          </div>
        </div>
        <h3 className="text-base mt-4 font-semibold">{topic.name}</h3>
      </div>
    </Link>
  );
}
