import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";

import { useSidebar } from "../../Home";
import useQuestions from "./useQuestions";
import EditScoreModal from "./EditScoreModal";
import { ActivityTypesProps } from "../Activities";
import { Question } from "../../../models/Question";
import { TopicSection } from "../../../models/Topic";
import { useAuth } from "../../../auth/AuthProvider";
import { ClassSection } from "../../../models/SchoolClass";
import BadgeIcon from "../../../components/Icons/BadgeIcon";
import useTopicService from "../../../services/useTopicService";
import useClassService from "../../../services/useClassService";
import { NewResponse, Response } from "../../../models/Response";
import useResponseService from "../../../services/useResponseService";
import useQuestionService from "../../../services/useQuestionService";

interface BlogActivityProps extends ActivityTypesProps {
  isTeacherMode: boolean;
}

export default function BlogQuestionActivity({
  onNext,
  onBack,
  activity,
  schoolClassId,
  isTeacherMode,
}: BlogActivityProps) {
  const { currentUser } = useAuth();
  const { isCollapse, setIsCollapse } = useSidebar();

  const { getClassSection } = useClassService();
  const { getTopicSection } = useTopicService();
  const { updateQuestion } = useQuestionService();
  const { updateResponse, createResponse } = useResponseService();
  const { topicSectionId, classSectionId } = useParams<{
    topicSectionId: string;
    classSectionId: string;
  }>();

  const [isGraded, setIsGraded] = useState(false);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(-1);
  const [classSection, setClassSection] = useState<ClassSection>();
  const [topicSection, setTopicSection] = useState<TopicSection>();

  const {
    activityScores,
    activityAnswers,
    activityQuestions,
    setActivityAnswers,
    setActivityQuestions,
  } = useQuestions(activity);

  useEffect(() => {
    init();

    async function init() {
      const [classSection, topicSection] = await Promise.all([
        getClassSection(classSectionId!),
        getTopicSection(topicSectionId!),
      ]);

      if (classSection.releaseGrades?.includes(topicSectionId!))
        setIsGraded(true);

      setTopicSection(topicSection);
      setClassSection(classSection);
    }
  }, [classSectionId, topicSectionId, getClassSection, getTopicSection]);

  useEffect(() => {
    setIsCollapse(true);

    return () => {
      setIsCollapse(false);
    };
  }, [isCollapse, setIsCollapse]);

  async function onSaveAnswer(answerIdx: number) {
    let updatedResponse: Response;
    let activityAnswer = activityAnswers[answerIdx];

    activityAnswer.timestamp = Timestamp.fromDate(new Date());
    if (activityAnswer.id) {
      updatedResponse = await updateResponse(
        activityAnswer.id,
        activityAnswer as Response
      );
    } else {
      updatedResponse = await createResponse(activityAnswer as NewResponse);
    }

    activityAnswers.splice(answerIdx, 1, updatedResponse);
    setActivityAnswers([...activityAnswers]);
  }

  function hasDueDateExpire(): boolean {
    const today = moment();
    const dueDate = moment(topicSection?.dueDate.toDate());

    return today.isAfter(dueDate);
  }

  function renderQuestionInput(activityQuestion: Question, idx: number) {
    switch (activityQuestion.questionType) {
      case "simple-text":
        return (
          <input
            placeholder="Your answer"
            value={activityAnswers[idx].answer}
            onChange={(e) => {
              let changedAnswer = activityAnswers[idx];
              changedAnswer.answer = e.target.value;
              activityAnswers.splice(idx, 1, changedAnswer);
              setActivityAnswers([...activityAnswers]);
            }}
            disabled={hasDueDateExpire()}
            onBlur={() => onSaveAnswer(idx)}
            className="mt-7 md:mt-3 xl:mt-7 bg-[#EFEFF3] h-12 py-4 text-sm text-[#9595A8] rounded-md w-full border-none"
          />
        );
      case "text-area":
        return (
          <textarea
            rows={8}
            placeholder="Your answer"
            value={activityAnswers[idx].answer}
            onChange={(e) => {
              let changedAnswer = activityAnswers[idx];
              changedAnswer.answer = e.target.value;
              activityAnswers.splice(idx, 1, changedAnswer);
              setActivityAnswers([...activityAnswers]);
            }}
            disabled={hasDueDateExpire()}
            onBlur={() => onSaveAnswer(idx)}
            className="mt-7 md:mt-3 xl:mt-7 bg-[#EFEFF3] py-4 text-sm text-[#9595A8] rounded-md w-full border-none"
          />
        );
      case "checkbox":
        return (
          <div className="space-y-5">
            {activityQuestion.options!.map((option) => (
              <div key={option.value} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={option.value}
                    type="checkbox"
                    checked={
                      activityAnswers[idx].answer
                        ?.split(",")
                        .includes(option.value) || false
                    }
                    aria-describedby="comments-description"
                    onChange={(e) => {
                      let changedAnswer = activityAnswers[idx];
                      const currentValues = changedAnswer.answer
                        ? changedAnswer.answer.split(",")
                        : [];
                      if (e.target.checked) {
                        currentValues.push(e.target.id);
                      } else {
                        const optionIdx = currentValues.findIndex(
                          (value: any) => value === e.target.id
                        );
                        currentValues.splice(optionIdx, 1);
                      }

                      changedAnswer.answer = currentValues.join(",");
                      activityAnswers.splice(idx, 1, changedAnswer);
                      setActivityAnswers([...activityAnswers]);
                      onSaveAnswer(idx);
                    }}
                    disabled={hasDueDateExpire()}
                    className="h-4 w-4 rounded border-gray-300 text-[#FFB155] focus:ring-[#FFB155]"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={option.value}
                    className="font-medium text-gray-900"
                  >
                    {option.label}
                  </label>{" "}
                </div>
              </div>
            ))}
          </div>
        );
      case "radio-button":
        return (
          <div className="space-y-4 sm:flex sm:flex-col sm:items-start sm:space-y-0">
            {activityQuestion.options!.map((option) => (
              <div key={option.value} className="flex items-center">
                <input
                  id={option.value}
                  type="radio"
                  name="options"
                  value={activityAnswers[idx].answer}
                  checked={activityAnswers[idx].answer === option.value}
                  onChange={(e) => {
                    let changedAnswer = activityAnswers[idx];
                    if (e.target.checked) {
                      changedAnswer.answer = option.value;
                    }
                    activityAnswers.splice(idx, 1, changedAnswer);
                    setActivityAnswers([...activityAnswers]);
                    onSaveAnswer(idx);
                  }}
                  disabled={hasDueDateExpire()}
                  className="h-4 w-4 border-gray-300 text-[#FFB155] focus:ring-[#FFB155]"
                />
                <label
                  htmlFor={option.value}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        );
    }
  }

  async function updateQuestionPoints(newScore: string) {
    const schoolId = classSection?.schoolClassId;
    const question = activityQuestions[currentQuestionIdx];

    if (question) {
      if (question.points) {
        question.points[schoolId!] = parseInt(newScore);
      } else {
        question.points = {};
        question.points[schoolId!] = parseInt(newScore);
      }
    }

    await updateQuestion(question.id, question);

    setCurrentQuestionIdx(-1);
    setActivityQuestions([...activityQuestions]);
  }

  function renderPoints(question: Question, questionIdx: number) {
    const points = question.points
      ? question.points[schoolClassId]
        ? question.points[schoolClassId]
        : question.defaultPoints
      : question.defaultPoints;

    return (
      <div className="flex items-center justify-end">
        <div className="relative inline-block">
          <BadgeIcon />
          <span className="text-[white] font-['Bold'] absolute transform top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4">
            {points}
          </span>
        </div>
        {isTeacherMode ? (
          <span
            onClick={() => setCurrentQuestionIdx(questionIdx)}
            className="cursor-pointer text-xs text-[#FFB155] ml-2 underline"
          >
            Edit score
          </span>
        ) : null}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-3 h-full">
      <div className="col-span-2 overflow-y-scroll border-r border-[#E0E0EC] pr-4">
        <img
          src={activity.heroImageUrl}
          alt=""
          className="h-auto w-full mb-4"
        />
        {currentUser?.userType === "teacher" &&
        isTeacherMode &&
        activity.teachersGuide ? (
          <div
            className="mt-4 mb-4 bg-[#EFEFF3] px-4 py-4 text-base text-[#0a0404] rounded-md w-full border-none"
            dangerouslySetInnerHTML={{ __html: activity.teachersGuide }}
          ></div>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: activity.contentHtml }}></div>
      </div>
      <div className="pl-11 md:pl-4 xl:pl-11 overflow-y-scroll flex flex-col gap-9 md:gap-4 xl:gap-9 md:col-span-2 xl:col-span-1">
        <div className="flex-grow overflow-y-scroll">
          {activityQuestions.map((activityQuestion, idx) => (
            <div
              key={activityQuestion.id}
              className="px-7 py-6 md:px-4 md:py-3 xl:px-7 xl:py-6 shadow-xl rounded-2xl md:rounded-xl xl:rounded-2xl"
            >
              <h3 className="font-['Semibold'] text-base md:text-sm xl:text-base">
                {`#${idx + 1} ${activityQuestion.title}`}
                {activityScores[idx] && isGraded
                  ? `Score: ${activityScores[idx]?.score}`
                  : ""}
              </h3>
              {renderPoints(activityQuestion, idx)}
              {(currentUser?.userType === "student" &&
                isGraded &&
                activityQuestion.correctAnswer) ||
              (currentUser?.userType === "teacher" &&
                isTeacherMode &&
                activityQuestion.correctAnswer) ? (
                <>
                  <h3 className="text-[#9595A8] text-[12px] font-['Semibold']">
                    Correct answer
                  </h3>
                  <div className="mt-4 mb-4 bg-[#4AC27A] px-4 py-2 rounded-xl text-[white]">
                    <span>{activityQuestion.correctAnswer}</span>
                  </div>
                </>
              ) : null}
              {renderQuestionInput(activityQuestion, idx)}
              {isGraded && activityScores[idx]?.comment ? (
                <span className="flex items-center gap-2 mt-2 text-sm font-[Semibold] text-[#9595A8]">
                  <ChatBubbleBottomCenterTextIcon className="h-4 w-4 min-h-[1rem] min-w-[1rem]" />
                  {activityScores[idx]?.comment || ""}
                </span>
              ) : null}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mt-auto">
          <button
            className="font-['Semibold'] text-base border border-[#E0E0EC] rounded-full px-10 py-4 leading-5"
            onClick={onBack}
          >
            Back
          </button>
          <button
            className="font-['Semibold'] text-base bg-[#FFB155] rounded-full px-10 py-4 leading-5"
            onClick={onNext}
          >
            Next
          </button>
        </div>
      </div>
      {currentQuestionIdx > -1 ? (
        <EditScoreModal
          open={currentQuestionIdx > -1}
          onClose={() => setCurrentQuestionIdx(-1)}
          onSubmit={(newScore: string) => updateQuestionPoints(newScore)}
        />
      ) : null}
    </div>
  );
}
