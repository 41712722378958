import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Topic } from "../../models/Topic";
import TopicGridItem from "./TopicGridItem";
import { useAuth } from "../../auth/AuthProvider";
import ContentView from "../../components/ContentView";
import { ClassSection } from "../../models/SchoolClass";
import useClassService from "../../services/useClassService";

export default function GradingsTopicsGrid() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { getClassSection, getClassSectionTopics } = useClassService();

  const [topics, setTopics] = useState<Topic[]>([]);
  const [, setClassSection] = useState<ClassSection>();

  useEffect(() => {
    init();

    async function init() {
      const classSection = await getClassSection(
        currentUser?.classSections[0]!
      );
      const topics = await getClassSectionTopics(
        currentUser?.classSections[0]!
      );

      setTopics(topics);
      setClassSection(classSection);
    }
  }, [currentUser, getClassSection, getClassSectionTopics]);

  return (
    <ContentView
      title="Gradings - Topics"
      onBack={() => navigate("/dashboard")}
    >
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
        {topics.map((topic) => (
          <TopicGridItem topic={topic} key={topic.id} />
        ))}
      </ul>
    </ContentView>
  );
}
