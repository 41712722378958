import {
  BanknotesIcon,
  GiftIcon,
  HeartIcon,
  LinkIcon,
  TagIcon,
  TruckIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

export default function BusinessModel() {
  return (
    <div className="grid w-full h-full grid-cols-5 grid-rows-3 border border-black">
      {/* Key Partners */}
      <div className="relative row-span-2 border border-black p-3">
        <LinkIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Key Partners</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
          Possimus vitae ea voluptate architecto dolor unde illum fuga ullam
          quas.
        </p>
      </div>
      {/* Key Activities */}
      <div className="relative border border-black p-3">
        <LinkIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Key Activities</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
        </p>
      </div>
      {/* Value Propositions */}
      <div className="relative row-span-2 border border-black p-3">
        <GiftIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Value Propositions</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
          Possimus vitae ea voluptate architecto dolor unde illum fuga ullam
          quas.
        </p>
      </div>
      {/* Customer Relationships */}
      <div className="relative border border-black p-3">
        <HeartIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Customer Relationships</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
        </p>
      </div>
      {/* Customer Segments */}
      <div className="relative row-span-2 border border-black p-3">
        <UsersIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Customer Segments</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
          Possimus vitae ea voluptate architecto dolor unde illum fuga ullam
          quas.
        </p>
      </div>
      {/* Key Resources */}
      <div className="relative border border-black p-3">
        <UsersIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Key Resources</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
        </p>
      </div>
      {/* Channels */}
      <div className="relative border border-black p-3">
        <TruckIcon className="h-6 w-6 absolute top-3 right-3" />
        <h3 className="mb-3">Channels</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
          maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
        </p>
      </div>
      <div className="grid col-span-5 border border-black grid-cols-2 grid-rows-1">
        {/* Cost Structure */}
        <div className="relative col-span-1 border border-black p-3">
          <TagIcon className="h-6 w-6 absolute top-3 right-3" />
          <h3 className="mb-3">Cost Structure</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
            maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
            Possimus vitae ea voluptate architecto dolor unde illum fuga ullam
            quas.
          </p>
        </div>
        {/* Revenue Streams */}
        <div className="relative col-span-1 border border-black p-3">
          <BanknotesIcon className="h-6 w-6 absolute top-3 right-3" />
          <h3 className="mb-3">Revenue Streams</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima
            maxime voluptatem culpa perspiciatis totam qui a sint quis ad!
            Possimus vitae ea voluptate architecto dolor unde illum fuga ullam
            quas.
          </p>
        </div>
      </div>
    </div>
  );
}
