import moment from "moment";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NewTopicSection, Topic, TopicSection } from "../../../../models/Topic";
import ContentView from "../../../../components/ContentView";
import useTopicService from "../../../../services/useTopicService";
import TopicSectionModal from "./TopicSectionModal";
import { DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/20/solid";
import { Timestamp } from "firebase/firestore";

export default function TopicSections() {
  const navigate = useNavigate();

  const {
    getTopics,
    getTopicSection,
    createTopicSection,
    updateTopicSection,
    getAllTopicSections,
  } = useTopicService();

  const [openModal, setOpenModal] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [topicSections, setTopicSections] = useState<TopicSection[]>([]);
  const [topicSection, setTopicSection] = useState<TopicSection | undefined>();

  useEffect(() => {
    init();
    async function init() {
      const topics = await getTopics();
      const topicSections = await getAllTopicSections();

      setTopics(topics);
      setTopicSections(topicSections);
    }
  }, [getTopics, getAllTopicSections]);

  function onCreateTopicSection() {
    setTopicSection(undefined);
    setOpenModal(true);
  }

  async function onEditTopicSection(topicSectionId: string) {
    const topicSection = await getTopicSection(topicSectionId);
    setTopicSection(topicSection);
    setOpenModal(true);
  }

  async function onDuplicateSection(topicSectionId: string) {
    const topicSection = await getTopicSection(topicSectionId);
    setTopicSection({
      ...(omit(topicSection, "id") as TopicSection),
    });
    setOpenModal(true);
  }

  async function onSaveTopicSection(
    name: string,
    order: number,
    topicId: string,
    headerImageUrl: string,
    submissionDeadline: string,
    dueDate: string,
    published: boolean
  ) {
    const formattedSubmissionDeadline = Timestamp.fromDate(
      moment(submissionDeadline, "YYYY-MM-DDTHH:mm").toDate()
    );
    const formattedDueDate = Timestamp.fromDate(
      moment(dueDate, "YYYY-MM-DDTHH:mm").toDate()
    );

    if (topicSection && topicSection.id) {
      const updatedTopicSection: TopicSection = {
        ...topicSection,
        name,
        order,
        topicId,
        headerImageUrl,
        published,
        submissionDeadline: formattedSubmissionDeadline,
        dueDate: formattedDueDate,
      };
      await updateTopicSection(topicSection.id, updatedTopicSection);
    } else {
      const createdTopicSection: NewTopicSection = {
        name,
        order,
        topicId,
        headerImageUrl,
        published,
        submissionDeadline: formattedSubmissionDeadline,
        dueDate: formattedDueDate,
      };
      await createTopicSection(createdTopicSection);
    }

    const topicSections = await getAllTopicSections();
    setTopicSections(topicSections);
    setTopicSection(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Topic Sections"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateTopicSection}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new topic section
        </span>
      }
    >
      <>
        <ul className="mt-3 p-2 grid grid-cols-1 gap-5 sm:grid-cols-1 sm:gap-6 lg:grid-cols-3">
          {topicSections.map((topicSection) => (
            <li
              key={topicSection.id}
              className="col-span-1 flex rounded-md shadow-sm"
            >
              <div className="flex flex-1 items-center justify-between rounded-r-md rounded-l-md border-l border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <span className="font-medium text-gray-900 hover:text-gray-600">
                    {topicSection.name}
                  </span>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={() => onEditTopicSection(topicSection.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={() => onDuplicateSection(topicSection.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <DocumentDuplicateIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {openModal && (
          <TopicSectionModal
            topicSection={topicSection}
            open={openModal}
            topics={topics}
            onClose={() => {
              setOpenModal(false);
              setTopicSection(undefined);
            }}
            onSubmit={onSaveTopicSection}
          />
        )}
      </>
    </ContentView>
  );
}
