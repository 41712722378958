import { useNavigate } from "react-router-dom";

import ContentView from "../../components/ContentView";
import BusinessModel from "../../components/BusinessModel";

export default function BusinessPlan() {
  const navigate = useNavigate();
  return (
    <ContentView title="Business Plan" onBack={() => navigate(-1)}>
      <div className="h-full flex flex-col">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-4">
          Coming Soon
        </h1>
        <BusinessModel />
      </div>
    </ContentView>
  );
}
