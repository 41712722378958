import {
  doc,
  query,
  collection,
  where,
  documentId,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { omit } from "lodash";

import { db } from "../db/firebase";
import { Team } from "../models/Team";
import { User } from "../models/User";
import { Response } from "../models/Response";
import { ClassSection } from "../models/SchoolClass";
import { useAuth } from "../auth/AuthProvider";
import { teamConverter } from "../db/converters/team";
import { classSectionConverter } from "../db/converters/schoolClass";
import { responseConverter } from "../db/converters/response";

class TeamNotFoundError extends Error {
  constructor() {
    super("Team not found.");
  }
}

export default function useTeamService() {
  const { currentUser } = useAuth();

  async function getTeam(teamId: string): Promise<Team> {
    const teamDocRef = doc(db, `teams`, teamId).withConverter(teamConverter);

    const teamDoc = await getDoc(teamDocRef);

    if (teamDoc.data() === undefined) throw new TeamNotFoundError();

    return teamDoc.data()!;
  }

  async function getTeamMembers(teamMembers: string[]): Promise<User[]> {
    const usersCollectionRef = query(
      collection(db, "users"),
      where(documentId(), "in", teamMembers)
    );

    const usersCollection = await getDocs(usersCollectionRef);

    return usersCollection.docs
      .filter((doc) => doc.id !== currentUser?.id)
      .map(
        (doc) =>
          ({
            ...omit(doc.data(), "password"),
            id: doc.id,
          } as User)
      );
  }

  async function getTeamClassSections(
    teamSections: string[]
  ): Promise<ClassSection[]> {
    const classSectionsCollectionRef = query(
      collection(db, "classSections"),
      where(documentId(), "in", teamSections)
    ).withConverter(classSectionConverter);

    const classSectionsCollection = await getDocs(classSectionsCollectionRef);

    return classSectionsCollection.docs.map((doc) => ({
      ...doc.data(),
      type: "team",
    }));
  }

  async function getTeamQuestionResponse(
    teamId: string,
    questionId: string
  ): Promise<Response | null> {
    const collectionRef = query(
      collection(db, "teams", teamId, "responses"),
      where("questionId", "==", questionId)
    ).withConverter(responseConverter);

    const result = await getDocs(collectionRef);

    if (result.docs.length === 0) return null;

    return result.docs[0].data();
  }

  return {
    getTeam,
    getTeamMembers,
    getTeamClassSections,
    getTeamQuestionResponse,
  };
}
