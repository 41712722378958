import { useState, useEffect } from "react";
import { Timestamp } from "firebase/firestore";
import { EyeIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";

import { Activity } from "../../models/Activity";
import { useAuth } from "../../auth/AuthProvider";
import ContentView from "../../components/ContentView";
import { TopicSectionSubmission } from "../../models/Topic";
import useTopicService from "../../services/useTopicService";
import useClassService from "../../services/useClassService";
import BlogActivity from "./ActivityTypeTemplate/BlogActivity";
import FullImageActivity from "./ActivityTypeTemplate/FullImageActivity";
import FillBlankActivity from "./ActivityTypeTemplate/FillBlankActivity";
import BlogQuestionActivity from "./ActivityTypeTemplate/BlogQuestionActivity";
import BlogTableQuestionActivity from "./ActivityTypeTemplate/BlogTableQuestionActivity";
import BlogTableDnDActivity from "./ActivityTypeTemplate/BlogTableDnDActivity";

export interface ActivityTypesProps {
  onNext: () => void;
  onBack: () => void;
  activity: Activity;
  schoolClassId: string;
}

export default function Activities() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { topicSectionId, classSectionId } = useParams<{
    topicSectionId: string;
    classSectionId: string;
  }>();
  const {
    getTopicSectionActivities,
    getTopicSectionSubmission,
    createTopicSectionSubmission,
    updateTopicSectionSubmission,
  } = useTopicService();
  const { getClassSection } = useClassService();

  const [isTeacherMode, setTeacherMode] = useState(false);
  const [topicSectionActivities, setTopicSectionActivities] = useState<
    Activity[]
  >([]);
  const [schoolClassId, setSchoolClassId] = useState<string>("");
  const [currentActivityIdx, setCurrentActivityIdx] = useState<number>(0);
  const [topicSectionSubmission, setTopicSectionSubmission] =
    useState<TopicSectionSubmission>();

  useEffect(() => {
    init();

    async function init() {
      const topicSectionActivities = await getTopicSectionActivities(
        topicSectionId!
      );
      const topicSectionSubmission = await getTopicSectionSubmission(
        topicSectionId!
      );
      const classSection = await getClassSection(classSectionId!);

      setSchoolClassId(classSection.schoolClassId);
      setTopicSectionActivities(
        topicSectionActivities.sort((a, b) => a.order - b.order)
      );
      setTopicSectionSubmission(topicSectionSubmission);
    }
  }, [
    topicSectionId,
    classSectionId,
    getClassSection,
    getTopicSectionActivities,
    getTopicSectionSubmission,
  ]);

  function renderCurrentActivity(currentActivityIdx: number) {
    if (!topicSectionActivities.length) return null;

    const currentActivity = topicSectionActivities[currentActivityIdx];

    switch (currentActivity.activityType) {
      case "blog":
        return (
          <BlogActivity
            onNext={onNextActivity}
            activity={currentActivity}
            onBack={onPreviousActivity}
            schoolClassId={schoolClassId}
          />
        );
      case "blog-question":
        return (
          <BlogQuestionActivity
            onNext={onNextActivity}
            activity={currentActivity}
            onBack={onPreviousActivity}
            isTeacherMode={isTeacherMode}
            schoolClassId={schoolClassId}
          />
        );
      case "table-blog-questions":
        return (
          <BlogTableQuestionActivity
            onNext={onNextActivity}
            activity={currentActivity}
            onBack={onPreviousActivity}
            isTeacherMode={isTeacherMode}
            schoolClassId={schoolClassId}
            tableColumns={currentActivity.tableColumns!}
          />
        );
      case "fullimage":
        return (
          <FullImageActivity
            onNext={onNextActivity}
            activity={currentActivity}
            onBack={onPreviousActivity}
            schoolClassId={schoolClassId}
          />
        );
      case "fill-blanks":
        return (
          <FillBlankActivity
            onNext={onNextActivity}
            activity={currentActivity}
            onBack={onPreviousActivity}
            schoolClassId={schoolClassId}
            isTeacherMode={isTeacherMode}
          />
        );
      case "table-blog-dnd-questions":
        return (
          <BlogTableDnDActivity
            onNext={onNextActivity}
            activity={currentActivity}
            onBack={onPreviousActivity}
            isTeacherMode={isTeacherMode}
            schoolClassId={schoolClassId}
            tableColumns={currentActivity.tableColumns!}
          />
        );
    }
  }

  async function onNextActivity() {
    if (currentActivityIdx === topicSectionActivities.length - 1) {
      await submitActivity();
      return navigate(-1);
    }

    setCurrentActivityIdx(currentActivityIdx + 1);
  }

  function onPreviousActivity() {
    if (currentActivityIdx === 0) return;

    setCurrentActivityIdx(currentActivityIdx - 1);
  }

  async function submitActivity() {
    if (topicSectionSubmission) {
      updateTopicSectionSubmission(topicSectionSubmission.id, {
        ...topicSectionSubmission,
        datetime: Timestamp.fromDate(new Date()),
      });
    } else {
      createTopicSectionSubmission({
        topicSectionId: topicSectionId!,
        datetime: Timestamp.fromDate(new Date()),
      });
    }
  }

  const currentActivity = topicSectionActivities[currentActivityIdx];

  return (
    <ContentView
      title={`Activity: ${currentActivity?.name || ""}`}
      customContent={
        currentUser?.userType === "teacher" ? (
          <span
            onClick={() => setTeacherMode(!isTeacherMode)}
            className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-28 ${
              isTeacherMode ? "text-[#FFB155]" : "text-[#9595A8]"
            }`}
          >
            <EyeIcon className="h-4 w-4" /> Toggle teacher mode
          </span>
        ) : undefined
      }
      onBack={() => navigate(-1)}
    >
      {renderCurrentActivity(currentActivityIdx)}
    </ContentView>
  );
}
