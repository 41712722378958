import { useEffect, useMemo } from "react";
import { Timestamp } from "firebase/firestore";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { useSidebar } from "../../Home";
import useQuestions from "./useQuestions";
import { ActivityTypesProps } from "../Activities";
import { Activity } from "../../../models/Activity";
import { NewResponse, Response } from "../../../models/Response";
import useResponseService from "../../../services/useResponseService";

interface BlogTableDnDActivityyProps extends ActivityTypesProps {
  tableColumns: string[];
  isTeacherMode: boolean;
}

export default function BlogTableDnDActivity({
  activity,
  tableColumns,
}: BlogTableDnDActivityyProps) {
  const { isCollapse, setIsCollapse } = useSidebar();

  useEffect(() => {
    setIsCollapse(true);

    return () => {
      setIsCollapse(false);
    };
  }, [isCollapse, setIsCollapse]);

  return (
    <div className="h-full flex flex-col">
      <DndProvider backend={HTML5Backend}>
        <Table activity={activity} tableColumns={tableColumns} />
      </DndProvider>
    </div>
  );
}

interface TableProps {
  activity: Activity;
  tableColumns: string[];
}

function Table({ activity, tableColumns }: TableProps) {
  // const [{ isOver, isOverCurrent }, drop] = useDrop(
  //   () => ({
  //     accept: "drag-in",
  //     drop(_item: any, monitor) {
  //       //onRemoveOption(_item.questionIdx, _item.colIdx);
  //     },
  //     collect: (monitor) => ({
  //       isOver: monitor.isOver(),
  //       isOverCurrent: monitor.isOver({ shallow: true }),
  //     }),
  //   }),
  //   []
  // );

  const { updateResponse, createResponse } = useResponseService();
  const { activityAnswers, activityQuestions, setActivityAnswers } =
    useQuestions(activity);

  const options = useMemo(
    () => [...activityQuestions.flatMap((question) => question.correctAnswer)],
    [activityQuestions]
  );

  const onDrop = (item: any, questionIdx: number, colIdx: number) => {
    if (item.questionIdx !== undefined && item.colIdx !== undefined) {
      const response = activityAnswers[item.questionIdx];
      response.answer[item.colIdx] = "";
    }

    const response = activityAnswers[questionIdx];
    response.answer[colIdx] = item.option;
    setActivityAnswers([...activityAnswers]);
    onSaveAnswer(questionIdx);
  };

  const onRemoveOption = (questionIdx: number, colIdx: number) => {
    const response = activityAnswers[questionIdx];
    response.answer[colIdx] = "";
    setActivityAnswers([...activityAnswers]);
    onSaveAnswer(questionIdx);
  };

  const remainOptions = useMemo(
    () =>
      options.filter((option) => {
        let optionUsed = false;
        activityAnswers.forEach((response) => {
          response.answer.forEach((resp: any) => {
            if (resp === option) optionUsed = true;
          });
        });
        return !optionUsed;
      }),
    [options, activityAnswers]
  );

  async function onSaveAnswer(answerIdx: number) {
    let updatedResponse: Response;
    let activityAnswer = activityAnswers[answerIdx];

    activityAnswer.timestamp = Timestamp.fromDate(new Date());
    if (activityAnswer.id) {
      updatedResponse = await updateResponse(
        activityAnswer.id,
        activityAnswer as Response
      );
    } else {
      updatedResponse = await createResponse(activityAnswer as NewResponse);
    }

    activityAnswers.splice(answerIdx, 1, updatedResponse);
    setActivityAnswers([...activityAnswers]);
  }

  console.log("activityAnswers", activityAnswers);

  return (
    <div>
      <div className="flex gap-2 flex-wrap mb-5">
        {remainOptions.map((option) => {
          return <DragInOption option={option} key={option} />;
        })}
      </div>
      {/* Table */}
      <div id="table" className="min-w-full rounded-lg overflow-hidden">
        {/* Table Head */}
        <div
          id="table-head"
          className={`h-[70px] grid items-center grid-cols-${
            tableColumns.length + 2
          }`}
          //className={`h-[70px] grid items-center grid-cols-4`}
          style={{
            backgroundColor: "rgba(221, 186, 248, 0.5)",
          }}
        >
          <div className="pl-4 pr-3 text-left text-sm font-[Semibold] text-gray-900 sm:pl-6 col-span-2" />
          {tableColumns.map((col, idx) => (
            <div
              key={idx}
              className="flex-1 pl-4 pr-3 text-sm font-[Semibold] text-gray-900 sm:pl-6 text-center"
            >
              {col}
            </div>
          ))}
        </div>
        {/* Table Body */}
        <div id="table-body">
          {activityQuestions.map((activityQuestion, questionIdx) => (
            <div
              key={questionIdx}
              className={`grid grid-cols-${tableColumns.length + 2} h-[90px]`}
            >
              <div
                style={{
                  backgroundColor: "rgba(221, 186, 248, 0.5)",
                }}
                className="flex items-center col-span-2 pl-6"
              >
                {activityQuestion.title}
              </div>
              {tableColumns.map((column, colIdx) => (
                <div
                  key={colIdx}
                  style={{
                    backgroundColor: "rgba(231, 235, 239, 0.2)",
                  }}
                  className={`whitespace-nowrap text-sm font-[Regular] text-gray-900 ${
                    questionIdx !== activityQuestions.length - 1
                      ? "border-b"
                      : ""
                  } ${colIdx !== tableColumns.length - 1 ? "border-r" : ""}`}
                >
                  <DropZoneCell
                    rowIdx={questionIdx}
                    colIdx={colIdx}
                    onDrop={onDrop}
                  >
                    {activityAnswers[questionIdx].answer[colIdx] ? (
                      <DragOutOption
                        colIdx={colIdx}
                        questionIdx={questionIdx}
                        onRemoveOption={onRemoveOption}
                        key={`drag-out-${questionIdx}-${colIdx}`}
                        value={activityAnswers[questionIdx].answer[colIdx]}
                      />
                    ) : null}
                  </DropZoneCell>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function DragInOption({ option }: any) {
  const [, drag] = useDrag(() => ({ item: { option }, type: "drag-in" }));

  return (
    <span
      ref={drag}
      style={{
        backgroundColor: "rgba(255, 177, 85, 0.4)",
        transform: "translate(0, 0)",
      }}
      className="inline-block cursor-pointer py-4 px-8 text-base rounded-lg max-w-[200px] min-w-[200px]"
    >
      {option}
    </span>
  );
}

function DragOutOption({
  value,
  questionIdx,
  colIdx,
  onRemoveOption,
}: {
  value: string;
  colIdx: number;
  questionIdx: number;
  onRemoveOption: (questionIdx: number, colIdx: number) => void;
}) {
  const [, drag] = useDrag(() => ({
    item: { questionIdx, colIdx, option: value },
    type: "drag-in",
  }));

  return (
    <span
      ref={drag}
      style={{
        transform: "translate(0, 0)",
      }}
      className="inline-block py-4 px-8 text-base rounded-lg bg-[#FFB155] relative lg:min-w-full lg:max-w-full xl:max-w-[200px] xl:min-w-[200px]"
    >
      {value}
      <div
        className="absolute w-6 h-6 bg-[#E0E0EC] rounded-full flex items-center justify-center cursor-pointer -top-3 -right-3"
        onClick={() => onRemoveOption(questionIdx, colIdx)}
      >
        <XMarkIcon height={20} width={20} />
      </div>
    </span>
  );
}

function DropZoneCell({ colIdx, rowIdx, children, onDrop }: any) {
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: "drag-in",
      drop(_item: unknown, monitor) {
        onDrop(_item, rowIdx, colIdx);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    []
  );
  return (
    <div
      ref={drop}
      className="w-full h-full flex items-center justify-center px-4"
    >
      {children}
    </div>
  );
}
