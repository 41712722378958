import { Link } from "react-router-dom";

import { Topic } from "../../models/Topic";
import OpenBookIcon from "../../components/Icons/OpenBookIcon";
interface TopicGridItemProps {
  topic: Topic;
  topicNumber: number;
}

export default function TopicGridItem({
  topic,
  topicNumber,
}: TopicGridItemProps) {
  return (
    <Link to={`${topic.id}`} className="rounded-xl overflow-hidden">
      <img
        className="h-[98px] w-full object-cover"
        alt=""
        src={topic.headerImageUrl}
      />
      <div className="pt-3 pb-16 px-6 bg-[#E8CBFF] rounded-b-xl relative">
        <div className="flex items-center gap-3">
          <div className="w-7 h-7 bg-[#A785C9] rounded-3xl flex items-center justify-center">
            <OpenBookIcon />
          </div>
          <span className="text-[#A785C9] text-xs font-['Semibold'] font-semibold">{`Topic #${topicNumber}`}</span>
        </div>
        <h3 className="text-base mt-4 font-semibold">{topic.name}</h3>
      </div>
    </Link>
  );
}
