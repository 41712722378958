import { FirestoreDataConverter } from "firebase/firestore";

import { Activity } from "../../models/Activity";

export const activityConverter: FirestoreDataConverter<Activity> = {
  toFirestore: (activity) => ({
    name: activity.name,
    order: activity.order,
    topicSectionId: activity.topicSectionId,
    activityType: activity.activityType,
    subtitle: activity.subtitle,
    nextButtonTitle: activity.nextButtonTitle,
    contentHtml: activity.contentHtml,
    tableColumns: activity.tableColumns,
    heroImageUrl: activity.heroImageUrl,
    teachersGuide: activity.teachersGuide,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      order: data.order,
      subtitle: data.subtitle,
      activityType: data.activityType,
      topicSectionId: data.topicSectionId,
      nextButtonTitle: data.nextButtonTitle,
      contentHtml: data.contentHtml,
      tableColumns: data.tableColumns,
      heroImageUrl: data.heroImageUrl,
      teachersGuide: data.teachersGuide,
    };
  },
};
