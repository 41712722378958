import { Link } from "react-router-dom";

import { TopicSection } from "../../../models/Topic";
import OpenBookIcon from "../../../components/Icons/OpenBookIcon";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

interface TopicSectionGridItemProps {
  isSubmitted: boolean;
  topicSection: TopicSection;
  topicSectionNumber: number;
}

export default function TopicSectionGridItem({
  isSubmitted,
  topicSection,
  topicSectionNumber,
}: TopicSectionGridItemProps) {
  return (
    <Link
      to={`topicSections/${topicSection.id}`}
      className="rounded-xl overflow-hidden"
    >
      <img
        className="h-[98px] w-full object-cover"
        alt=""
        src={topicSection.headerImageUrl}
      />
      <div className="pt-3 pb-16 px-6 bg-[#E8CBFF] rounded-b-xl relative">
        <div className="flex items-center gap-3">
          <div className="w-7 h-7 bg-[#A785C9] rounded-3xl flex items-center justify-center">
            <OpenBookIcon />
          </div>
          <span className="text-[#A785C9] text-xs font-['Semibold'] font-semibold">{`Topic Section #${topicSectionNumber}`}</span>
        </div>
        <h3 className="text-base mt-4 font-semibold">{topicSection.name}</h3>
        {isSubmitted && (
          <CheckBadgeIcon className="h-7 w-7 text-green-600 absolute right-3 bottom-3" />
        )}
      </div>
    </Link>
  );
}
