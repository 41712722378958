import { useEffect, useState } from "react";

import { Score } from "../../../models/Score";
import { Question } from "../../../models/Question";
import { Response } from "../../../models/Response";
import { Activity } from "../../../models/Activity";
import useUserService from "../../../services/useUserService";
import useActivityService from "../../../services/useActivityService";

export default function useQuestions(activity: Activity) {
  const { id: activityId } = activity;
  const { getActivityQuestions } = useActivityService();
  const { getUserResponses, getUserScores } = useUserService();

  const [activityQuestions, setActivityQuestions] = useState<Question[]>([]);
  const [activityScores, setActivityScores] = useState<Array<Score | null>>([]);
  const [activityAnswers, setActivityAnswers] = useState<Partial<Response>[]>(
    []
  );

  useEffect(() => {
    getQuestions();

    function createAnswerValue() {
      let answerValue: any = "";

      if (
        activity.activityType === "table-blog-questions" ||
        activity.activityType === "table-blog-dnd-questions"
      ) {
        answerValue = activity.tableColumns!.map((column) => "");
      }

      return answerValue;
    }

    async function getQuestions() {
      setActivityScores([]);
      setActivityAnswers([]);
      setActivityQuestions([]);

      const activityScores = [];
      const activityAnswers = [];
      const activityQuestions = await getActivityQuestions(activityId);
      const questionIds = activityQuestions.map((question) => question.id);

      const scores = await getUserScores(questionIds);
      const answers = await getUserResponses(questionIds);

      activityQuestions.sort(
        (questionA, questionB) => questionA.order - questionB.order
      );

      for (let i = 0; i < activityQuestions.length; i++) {
        const question = activityQuestions[i];
        const score =
          scores.find((score) => score.questionId === question.id) || null;
        const answer = answers.find(
          (answer) => answer.questionId === question.id
        );

        let partialAnswer: Partial<Response> = {
          questionId: question.id,
          answer: createAnswerValue(),
        };

        if (answer) {
          partialAnswer.id = answer.id;
          partialAnswer.answer = answer.answer;
        }

        activityScores.push(score);
        activityAnswers.push(partialAnswer);
      }

      setActivityScores(activityScores);
      setActivityAnswers(activityAnswers);
      setActivityQuestions(activityQuestions);
    }
  }, [
    activity,
    activityId,
    getUserScores,
    getUserResponses,
    getActivityQuestions,
  ]);

  return {
    activityScores,
    activityAnswers,
    activityQuestions,
    setActivityAnswers,
    setActivityQuestions,
  };
}
