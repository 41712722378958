import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/20/solid";

import {
  SchoolClass,
  ClassSection,
  NewClassSection,
} from "../../../models/SchoolClass";
import { Topic } from "../../../models/Topic";
import ClassSectionModal from "./ClassSectionModal";
import ContentView from "../../../components/ContentView";
import useClassService from "../../../services/useClassService";
import useTopicService from "../../../services/useTopicService";

export default function ClassSections() {
  const navigate = useNavigate();
  const {
    getClasses,
    getClassSections,
    getClassSection,
    createClassSection,
    updateClassSection,
  } = useClassService();
  const { getTopics } = useTopicService();

  const [topics, setTopics] = useState<Topic[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [classes, setClasses] = useState<SchoolClass[]>([]);
  const [classSections, setClassSections] = useState<ClassSection[]>([]);
  const [classSection, setClassSection] = useState<ClassSection | undefined>();

  useEffect(() => {
    init();

    async function init() {
      const topics = await getTopics();
      const classes = await getClasses();
      const classSections = await getClassSections();

      setTopics(topics);
      setClasses(classes);
      setClassSections(classSections);
    }
  }, [getTopics, getClasses, getClassSections]);

  function onCreateClassSection() {
    setClassSection(undefined);
    setOpenModal(true);
  }

  async function onEditClassSection(classSectionId: string) {
    const classSection = await getClassSection(classSectionId);
    setClassSection(classSection);
    setOpenModal(true);
  }

  async function onSaveClassSection(
    classname: string,
    code: string,
    schoolClassId: string,
    topicsIds: string[]
  ) {
    if (classSection) {
      const updatedClassClassSection: ClassSection = {
        ...classSection,
        name: classname,
        schoolClassId,
        code,
        topicsIds,
      };

      await updateClassSection(classSection.id, updatedClassClassSection);
    } else {
      const createdClassSection: NewClassSection = {
        name: classname,
        schoolClassId,
        code,
        topicsIds,
      };

      await createClassSection(createdClassSection);
    }

    const classSections = await getClassSections();

    setClassSections(classSections);
    setClassSection(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Class Sections"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateClassSection}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new class section
        </span>
      }
    >
      <>
        <ul className="mt-3 p-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {classSections.map((classSection) => (
            <li
              key={classSection.id}
              className="col-span-1 flex rounded-md shadow-sm"
            >
              <div className="flex flex-1 items-center justify-between rounded-r-md rounded-l-md border-l border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <span className="font-medium text-gray-900 hover:text-gray-600">
                    {classSection.name}
                  </span>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={() => onEditClassSection(classSection.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-red-400 hover:text-red-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button> */}
                </div>
              </div>
            </li>
          ))}
        </ul>
        {openModal && (
          <ClassSectionModal
            topics={topics}
            open={openModal}
            classes={classes}
            classSection={classSection}
            onClose={() => {
              setOpenModal(false);
              setClassSection(undefined);
            }}
            onSubmit={onSaveClassSection}
          />
        )}
      </>
    </ContentView>
  );
}
