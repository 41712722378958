import { ChevronLeftIcon } from "@heroicons/react/20/solid";
interface ContentViewProps {
  title: string;
  onBack?: () => void;
  customContent?: JSX.Element;
  children: JSX.Element | null;
}

export default function ContentView({
  title,
  onBack,
  children,
  customContent,
}: ContentViewProps) {
  return (
    <div className="h-full relative rounded-2xl bg-white flex flex-col">
      <div className="flex items-center justify-between px-6 pt-9 pb-5">
        <div className="flex items-center gap-14">
          {onBack ? (
            <div
              onClick={onBack}
              className="w-8 h-8  rounded-full border border-[#E0E0EC] flex items-center justify-center hover:cursor-pointer"
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </div>
          ) : (
            <div className="w-8 h-8" />
          )}
          <h2 className="text-3xl font-['Bold'] font-bold text-[#1a1a1d]">
            {title}
          </h2>
        </div>
        <div className="flex items-center">
          {customContent && <div>{customContent}</div>}
          <img
            className="h-8 w-8 rounded-full"
            src={
              "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            }
            alt=""
          />
        </div>
      </div>
      <div className="h-px bg-[#9595A8] opacity-25" />
      <div className="h-[90%] px-20 py-6 overflow-y-scroll">{children}</div>
    </div>
  );
}
