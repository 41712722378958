import { FirestoreDataConverter } from "firebase/firestore";
import { Response } from "../../models/Response";

export const responseConverter: FirestoreDataConverter<Response> = {
  toFirestore: (response) => ({
    answer: response.answer,
    timestamp: response.timestamp,
    questionId: response.questionId,
    username: response.username,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      answer: data.answer,
      timestamp: data.timestamp,
      questionId: data.questionId,
      username: data.username,
    };
  },
};
