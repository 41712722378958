import { omit } from "lodash";
import { doc, addDoc, collection, updateDoc } from "firebase/firestore";

import { db } from "../db/firebase";
import { scoreConverter } from "../db/converters/score";
import { NewScore, Score } from "../models/Score";

export default function useScoreService() {
  async function createScore(
    studentId: string,
    score: NewScore
  ): Promise<Score> {
    const newScore = await addDoc(
      collection(db, `users/${studentId}/scores`).withConverter(scoreConverter),
      score
    );

    return {
      ...score,
      id: newScore.id,
    };
  }

  async function updateScore(
    studentId: string,
    scoreId: string,
    score: Score
  ): Promise<Score> {
    await updateDoc(doc(db, "users", studentId, "scores", scoreId), {
      ...omit(score, "id"),
    });

    return score;
  }

  return {
    createScore,
    updateScore,
  };
}
