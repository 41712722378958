import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA_jNmCORJ1gihQ1vjwQT1Qy_KLjpbIPX8",
  authDomain: "nextup-courses.firebaseapp.com",
  projectId: "nextup-courses",
  storageBucket: "nextup-courses.appspot.com",
  messagingSenderId: "250899283394",
  appId: "1:250899283394:web:2c9a1ca6a9b352a6bc6a5b",
};

const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
