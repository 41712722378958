import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/20/solid";

import { Question } from "../../models/Question";
import { Response } from "../../models/Response";
import useActivityService from "../../services/useActivityService";

export default function Questions() {
  const { getActivityQuestions } = useActivityService();

  const { activityId } = useParams<{ activityId: string }>();

  const [currentAnswer, setCurrentAnswer] = useState("");
  const [, setQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [currentResponse] = useState<Response | null>();

  useEffect(() => {
    init();

    async function init() {
      //const questions = await getTopicQuestions(topicId!);
      const questions = await getActivityQuestions(activityId!);
      console.log("questions", questions);

      //const team = await getUserTeam();

      // questions.forEach((question) => (question.type = "individual"));

      // if (team) {
      //   const topic = await getTopic(topicId!);
      //   const classSection = await getClassSection(topic.classSectionId);

      //   if (team.sections.includes(classSection.id)) {
      //     questions.forEach((question) => (question.type = "team"));
      //   }
      // }

      if (questions.length) {
        const question = questions[0];

        //let response: Response | null;

        // if (question.type === "individual") {
        //   response = await getUserQuestionResponse(question.id);
        // } else {
        //   response = await getTeamQuestionResponse(team?.id!, question.id);
        // }

        // setTeam(team);
        setCurrentQuestion(question);
        // setCurrentResponse(response);
        // setCurrentAnswer(response?.answer || "");
      }

      setQuestions(questions);
    }
  }, [activityId, getActivityQuestions]);

  // function hasDueDateExpire(questionDate: Timestamp): boolean {
  //   const today = moment();
  //   const dueDate = moment(questionDate.toDate());

  //   return today.isAfter(dueDate);
  // }

  async function onSubmitAnswer(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // if (hasDueDateExpire(currentQuestion?.dueDate!))
    //   return setCurrentResponse({ ...currentResponse! });

    //let updatedResponse: Response;

    if (currentResponse) {
      // const response: Response = {
      //   ...currentResponse,
      //   answer: currentAnswer,
      //   timestamp: Timestamp.fromDate(new Date()),
      // };
      // if (currentQuestion?.type === "individual") {
      //   updatedResponse = await updateResponse(currentResponse.id, response);
      // } else {
      //   updatedResponse = await updateTeamResponse(
      //     team?.id!,
      //     currentResponse.id,
      //     response
      //   );
      // }
    } else {
      // const response: NewResponse = {
      //   answer: currentAnswer,
      //   questionId: currentQuestion?.id!,
      //   timestamp: Timestamp.fromDate(new Date()),
      // };
      // if (currentQuestion?.type === "individual") {
      //   updatedResponse = await createResponse(response);
      // } else {
      //   updatedResponse = await createTeamResponse(team?.id!, response);
      // }
    }

    //setCurrentResponse(updatedResponse);
  }

  return currentQuestion ? (
    <form className="relative" onSubmit={onSubmitAnswer}>
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        <label htmlFor="title" className="sr-only">
          {currentQuestion?.title}
        </label>
        <input
          disabled
          id="title"
          name="title"
          type="text"
          className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
          placeholder={currentQuestion?.title}
        />
        <label htmlFor="description" className="sr-only">
          Answer
        </label>
        <textarea
          rows={2}
          id="description"
          name="description"
          placeholder="Write your answer"
          value={currentAnswer || ""}
          onChange={(e) => {
            setCurrentAnswer(e.target.value);
          }}
          className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        />

        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-9" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-px bottom-0">
        <div className="flex flex-nowrap items-center justify-end space-x-2 px-2 py-2 sm:px-3">
          {/* {currentQuestion.type === "team" && (
            <UsersIcon
              className="h-5 w-5 flex-shrink-0 sm:-ml-1 text-indigo-600"
              aria-hidden="true"
            />
          )} */}
          <span className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 sm:px-3">
            <CalendarIcon
              className="h-5 w-5 flex-shrink-0 sm:-ml-1 text-gray-500"
              aria-hidden="true"
            />
            {/* {currentQuestion.dueDate.toDate().toUTCString()} */}
          </span>
        </div>
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            <div className="flex-shrink-0">
              {/* {hasDueDateExpire(currentQuestion.dueDate) ? (
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              ) : (
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              )} */}
            </div>
            <div className="ml-3">
              {/* <p
                className={`text-sm font-medium ${
                  hasDueDateExpire(currentQuestion.dueDate)
                    ? "text-red-800"
                    : "text-green-800"
                }`}
              >
                {hasDueDateExpire(currentQuestion.dueDate)
                  ? "You can no longer submit your answer."
                  : "You are still able to submit your answer."}
              </p> */}
            </div>
          </div>
          <div className="flex-shrink-0">
            {/* <button
              type="submit"
              disabled={hasDueDateExpire(currentQuestion.dueDate)}
              className={`inline-flex items-center rounded-md ${
                hasDueDateExpire(currentQuestion.dueDate)
                  ? "bg-gray-300"
                  : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
              }  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
            >
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </form>
  ) : null;
}
