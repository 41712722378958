import { Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  if (!auth.currentUser) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
