import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Topic } from "../../models/Topic";
import TopicGridItem from "./TopicGridItem";
import ContentView from "../../components/ContentView";
import { ClassSection } from "../../models/SchoolClass";
import useClassService from "../../services/useClassService";

export default function TopicsGrid() {
  const navigate = useNavigate();
  const { classSectionId } = useParams<{ classSectionId: string }>();
  const { getClassSection, getClassSectionTopics } = useClassService();

  const [topics, setTopics] = useState<Topic[]>([]);
  const [, setClassSection] = useState<ClassSection>();

  useEffect(() => {
    init();

    async function init() {
      const classSection = await getClassSection(classSectionId!);
      const topics = await getClassSectionTopics(classSectionId!);

      setClassSection(classSection);
      setTopics(topics.sort((a, b) => a.order - b.order));
    }
  }, [classSectionId, getClassSection, getClassSectionTopics]);

  return (
    <ContentView title="Topics" onBack={() => navigate("/dashboard")}>
      <ul className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
        {topics.map((topic, idx) => (
          <TopicGridItem topic={topic} key={topic.id} topicNumber={idx + 1} />
        ))}
      </ul>
    </ContentView>
  );
}
