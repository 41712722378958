import { FirestoreDataConverter } from "firebase/firestore";

import { User } from "../../models/User";

export const userConverter: FirestoreDataConverter<User> = {
  toFirestore: (user) => ({
    firstname: user.firstname,
    lastname: user.lastname,
    classSections: user.classSections,
    username: user.username,
    userType: user.userType,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      userType: data.userType,
      classSections: data.classSections,
    };
  },
};
