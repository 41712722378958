import {
  query,
  collection,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { omit } from "lodash";

import { db } from "../db/firebase";
import { Question } from "../models/Question";
import { useAuth } from "../auth/AuthProvider";
import {
  NewTopic,
  NewTopicSection,
  NewTopicSectionSubmission,
  Topic,
  TopicSection,
  TopicSectionSubmission,
} from "../models/Topic";
import { Activity } from "../models/Activity";
import { activityConverter } from "../db/converters/activity";
import { questionConverter } from "../db/converters/question";
import {
  topicConverter,
  topicSectionConverter,
  topicSectionSubmissionConverter,
} from "../db/converters/topic";
import { useCallback } from "react";

class TopicNotFoundError extends Error {
  constructor() {
    super("Topic not found.");
  }
}

class TopicSectionFoundError extends Error {
  constructor() {
    super("Topic section not found.");
  }
}

export default function useTopicService() {
  const { currentUser } = useAuth();

  const getTopic = useCallback(async (topicId: string): Promise<Topic> => {
    const topicDocRef = doc(db, `topics`, topicId).withConverter(
      topicConverter
    );

    const topicDoc = await getDoc(topicDocRef);

    if (topicDoc.data() === undefined) throw new TopicNotFoundError();

    return topicDoc.data()!;
  }, []);

  const getTopics = useCallback(async (): Promise<Topic[]> => {
    const topicCollectionRef = query(collection(db, "topics")).withConverter(
      topicConverter
    );

    const topicCollection = await getDocs(topicCollectionRef);

    return topicCollection.docs.map((doc) => doc.data());
  }, []);

  const getTopicSection = useCallback(
    async (topicSectionId: string): Promise<TopicSection> => {
      const topicSectionDocRef = doc(
        db,
        `topicSections`,
        topicSectionId
      ).withConverter(topicSectionConverter);

      const topicSectionDoc = await getDoc(topicSectionDocRef);

      if (topicSectionDoc.data() === undefined)
        throw new TopicSectionFoundError();

      return topicSectionDoc.data()!;
    },
    []
  );

  const getTopicSections = useCallback(
    async (topicId: string): Promise<TopicSection[]> => {
      const topicSectionsCollectionRef = query(
        collection(db, "topicSections"),
        where("topicId", "==", topicId)
      ).withConverter(topicSectionConverter);

      const topicSectionsCollection = await getDocs(topicSectionsCollectionRef);

      return topicSectionsCollection.docs.map((doc) => doc.data());
    },
    []
  );

  async function createTopic(topic: NewTopic): Promise<Topic> {
    const newTopic = await addDoc(
      collection(db, `topics`).withConverter(topicConverter),
      topic
    );

    return {
      ...topic,
      id: newTopic.id,
    };
  }

  async function updateTopic(topicId: string, topic: Topic): Promise<Topic> {
    await updateDoc(doc(db, "topics", topicId), { ...omit(topic, "id") });

    return topic;
  }

  async function createTopicSection(
    topicSection: NewTopicSection
  ): Promise<TopicSection> {
    const newTopicSection = await addDoc(
      collection(db, `topicSections`).withConverter(topicSectionConverter),
      topicSection
    );

    return {
      ...topicSection,
      id: newTopicSection.id,
    };
  }

  async function updateTopicSection(
    topicSectionId: string,
    topicSection: TopicSection
  ): Promise<TopicSection> {
    await updateDoc(doc(db, "topicSections", topicSectionId), {
      ...omit(topicSection, "id"),
    });

    return topicSection;
  }

  const getTopicSectionActivities = useCallback(
    async (topicSectionId: string): Promise<Activity[]> => {
      const topicSectionActivitiesCollectionRef = query(
        collection(db, "activities"),
        where("topicSectionId", "==", topicSectionId)
      ).withConverter(activityConverter);

      const topicSectionActivitiesCollection = await getDocs(
        topicSectionActivitiesCollectionRef
      );

      return topicSectionActivitiesCollection.docs.map((doc) => doc.data());
    },
    []
  );

  async function getTopicQuestions(topicId: string): Promise<Question[]> {
    const questionsCollectionRef = query(
      collection(db, "questions"),
      where("topicId", "==", topicId)
    ).withConverter(questionConverter);

    const questionsCollection = await getDocs(questionsCollectionRef);

    return questionsCollection.docs.map((doc) => doc.data());
  }

  const getTopicSectionSubmission = useCallback(
    async (
      topicSectionId: string
    ): Promise<TopicSectionSubmission | undefined> => {
      const topicSectionSubmissionDocRef = query(
        collection(db, `users/${currentUser?.id}/topicSectionSubmissions`),
        where("topicSectionId", "==", topicSectionId)
      ).withConverter(topicSectionSubmissionConverter);

      const result = await getDocs(topicSectionSubmissionDocRef);

      if (result.docs.length === 0) return undefined;

      return result.docs[0].data();
    },
    [currentUser]
  );

  const getTopicSectionSubmissions = useCallback(
    async (topicSectionIds: string[]): Promise<TopicSectionSubmission[]> => {
      const topicSectionSubmissionsCollectionRef = query(
        collection(db, `users/${currentUser?.id}/topicSectionSubmissions`),
        where("topicSectionId", "in", topicSectionIds)
      ).withConverter(topicSectionSubmissionConverter);

      const topicSectionSubmissionsCollections = await getDocs(
        topicSectionSubmissionsCollectionRef
      );

      return topicSectionSubmissionsCollections.docs.map((doc) => doc.data());
    },
    [currentUser]
  );

  async function createTopicSectionSubmission(
    topicSectionSubmission: NewTopicSectionSubmission
  ): Promise<void> {
    await addDoc(
      collection(
        db,
        `users/${currentUser?.id}/topicSectionSubmissions`
      ).withConverter(topicSectionSubmissionConverter),
      topicSectionSubmission
    );
  }

  async function updateTopicSectionSubmission(
    topicSectionSubmissionId: string,
    topicSectionSubmission: TopicSectionSubmission
  ): Promise<void> {
    await updateDoc(
      doc(
        db,
        `users/${currentUser?.id}/topicSectionSubmissions`,
        topicSectionSubmissionId
      ),
      { ...omit(topicSectionSubmission, "id") }
    );
  }

  const getAllTopicSections = useCallback(async (): Promise<TopicSection[]> => {
    const topicSectionsCollectionRef = query(
      collection(db, "topicSections")
    ).withConverter(topicSectionConverter);

    const topicSectionsCollection = await getDocs(topicSectionsCollectionRef);

    return topicSectionsCollection.docs.map((doc) => doc.data());
  }, []);

  return {
    getTopic,
    getTopics,
    createTopic,
    updateTopic,
    getTopicSection,
    getTopicSections,
    getTopicQuestions,
    createTopicSection,
    updateTopicSection,
    getAllTopicSections,
    getTopicSectionSubmission,
    getTopicSectionActivities,
    getTopicSectionSubmissions,
    createTopicSectionSubmission,
    updateTopicSectionSubmission,
  };
}
