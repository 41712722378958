import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/20/solid";

import TopicModal from "./TopicModal";
import { NewTopic, Topic } from "../../../models/Topic";
import ContentView from "../../../components/ContentView";
import { ClassSection } from "../../../models/SchoolClass";
import useTopicService from "../../../services/useTopicService";
import useClassService from "../../../services/useClassService";

export default function Topics() {
  const navigate = useNavigate();
  const { getClassSections, getClass, updateClass } = useClassService();
  const { getTopic, getTopics, createTopic, updateTopic } = useTopicService();

  const [openModal, setOpenModal] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [topic, setTopic] = useState<Topic | undefined>();
  const [classSections, setClassSections] = useState<ClassSection[]>([]);

  useEffect(() => {
    init();
    async function init() {
      const topics = await getTopics();
      const classSections = await getClassSections();

      setTopics(topics);
      setClassSections(classSections);
    }
  }, [getTopics, getClassSections]);

  function onCreateTopic() {
    setTopic(undefined);
    setOpenModal(true);
  }

  async function onEditTopic(topicId: string) {
    const topic = await getTopic(topicId);
    setTopic(topic);
    setOpenModal(true);
  }

  async function onSaveTopic(
    name: string,
    order: number,
    classSectionId: string,
    headerImageUrl: string
  ) {
    if (topic) {
      const updatedTopic: Topic = {
        ...topic,
        name,
        order,
        classSectionId,
        headerImageUrl,
      };
      await updateTopic(topic.id, updatedTopic);
    } else {
      const createdTopic: NewTopic = {
        name,
        order,
        classSectionId,
        headerImageUrl,
      };
      const newTopic = await createTopic(createdTopic);

      const classSection = classSections.find(
        (classSection) => classSection.id === classSectionId
      );

      const schoolClass = await getClass(classSection!.schoolClassId);

      schoolClass.topicsIds.push(newTopic.id);
      await updateClass(schoolClass.id, schoolClass);
    }

    const topics = await getTopics();
    setTopics(topics);
    setTopic(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Topics"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateTopic}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new topic
        </span>
      }
    >
      <>
        <ul className="mt-3 p-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {topics.map((topic) => (
            <li key={topic.id} className="col-span-1 flex rounded-md shadow-sm">
              <div className="flex flex-1 items-center justify-between rounded-r-md rounded-l-md border-l border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <span className="font-medium text-gray-900 hover:text-gray-600">
                    {topic.name}
                  </span>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={() => onEditTopic(topic.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {openModal && (
          <TopicModal
            topic={topic}
            open={openModal}
            classSections={classSections}
            onClose={() => {
              setOpenModal(false);
              setTopic(undefined);
            }}
            onSubmit={onSaveTopic}
          />
        )}
      </>
    </ContentView>
  );
}
