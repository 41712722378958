export default function OpenBookIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.97214 13.1949C5.56995 11.6514 3.66848 10.6515 1.60205 10.3712C1.36687 10.345 1.14973 10.2326 0.992487 10.0558C0.835248 9.87892 0.749048 9.65013 0.750506 9.41349V1.70732C0.750654 1.5704 0.780158 1.43511 0.837028 1.31056C0.893899 1.18601 0.976814 1.0751 1.08018 0.985309C1.18355 0.89552 1.30497 0.82894 1.43625 0.790057C1.56754 0.751173 1.70563 0.740891 1.84123 0.759904C3.81723 1.08781 5.62492 2.07247 6.97214 3.55474V13.1949Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.97217 13.1949C8.37436 11.6513 10.2758 10.6515 12.3423 10.3712C12.5774 10.3449 12.7946 10.2326 12.9518 10.0557C13.1091 9.87888 13.1953 9.65008 13.1938 9.41345V1.70728C13.1937 1.57026 13.1641 1.43486 13.1072 1.31023C13.0503 1.1856 12.9673 1.07463 12.8638 0.984825C12.7603 0.895019 12.6387 0.828464 12.5073 0.789655C12.3759 0.750845 12.2377 0.740684 12.1021 0.759858C10.1264 1.08799 8.31912 2.07263 6.97217 3.5547V13.1949Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
