// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brandBackground {
    /* background: rgb(255,170,109);
    background: radial-gradient(at bottom left, rgba(255,170,109,0.80) 5%, rgba(255,109,109,0.80) 12%, rgba(0,0,0,1) 25%); */
}`, "",{"version":3,"sources":["webpack://./src/features/Auth/styles.css"],"names":[],"mappings":"AAAA;IACI;4HACwH;AAC5H","sourcesContent":[".brandBackground {\n    /* background: rgb(255,170,109);\n    background: radial-gradient(at bottom left, rgba(255,170,109,0.80) 5%, rgba(255,109,109,0.80) 12%, rgba(0,0,0,1) 25%); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
