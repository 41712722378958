import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { Transition, Dialog, Listbox } from "@headlessui/react";

import { Activity } from "../../../models/Activity";
import {
  Question,
  QuestionOption,
  QuestionType,
} from "../../../models/Question";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface QuestionModalProps {
  open: boolean;
  onClose: () => void;
  question?: Question;
  activities: Activity[];
  onSubmit: (
    title: string,
    order: number,
    activityId: string,
    correctAnswer: string,
    options: QuestionOption[],
    questionType: QuestionType,
    defaultPoints: number
  ) => void;
}

export default function QuestionModal({
  open,
  onClose,
  onSubmit,
  question,
  activities,
}: QuestionModalProps) {
  const questionTypes: QuestionType[] = [
    "simple-text",
    "text-area",
    "radio-button",
    "checkbox",
    "dropdown",
  ];

  const [correctAnswer, setCorrectAnswer] = useState(
    question?.correctAnswer || ""
  );
  const [defaultPoints, setDefaultPoints] = useState(
    question?.defaultPoints || ""
  );
  const [order, setOrder] = useState(question?.order || "");
  const [title, setTitle] = useState(question?.title || "");
  const [selectedActivity, setSelectedActivity] = useState<string>(
    question?.activityId || ""
  );
  const [selectedQuestionType, setSelectecQuestionType] =
    useState<QuestionType>(question?.questionType || "simple-text");
  const [questionOptions, setQuestionOptions] = useState<QuestionOption[]>(
    question?.options || []
  );

  const [newQuestionOptionValue, setNewQuestionOptionValue] = useState("");
  const [newQuestionOptionLabel, setNewQuestionOptionLabel] = useState("");

  function getSelectedActivity(activityId: string): Activity {
    return activities.find(
      (activity) => activity.id === activityId
    ) as Activity;
  }

  function renderQuestionType() {
    switch (selectedQuestionType) {
      case "simple-text":
      case "text-area":
        return null;
      case "radio-button":
      case "checkbox":
      case "dropdown":
        return (
          <div className="mt-2 p-2 bg-gray-50 rounded-md">
            <div className="flex flex-row gap-4">
              <div className="flex flex-row gap-4 items-center">
                <label
                  htmlFor="newQuestionOptionLabel"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Option label
                </label>
                <div className="">
                  <input
                    type="text"
                    id="newQuestionOptionLabel"
                    name="newQuestionOptionLabel"
                    value={newQuestionOptionLabel}
                    onChange={(e) => setNewQuestionOptionLabel(e.target.value)}
                    className="block w-full text-xs rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <label
                  htmlFor="newQuestionOptionValue"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Option value
                </label>
                <div className="">
                  <input
                    type="text"
                    id="newQuestionOptionValue"
                    name="newQuestionOptionValue"
                    value={newQuestionOptionValue}
                    onChange={(e) => setNewQuestionOptionValue(e.target.value)}
                    className="block w-full text-xs rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                  />
                </div>
              </div>
              <span
                onClick={() => {
                  if (newQuestionOptionLabel && newQuestionOptionValue) {
                    questionOptions.push({
                      value: newQuestionOptionValue,
                      label: newQuestionOptionLabel,
                    });
                    setNewQuestionOptionLabel("");
                    setNewQuestionOptionValue("");
                  }
                }}
                className={`${
                  newQuestionOptionLabel && newQuestionOptionValue
                    ? "cursor-pointer"
                    : ""
                } flex items-center font-[Semibold] text-sm gap-3 mr-56 ${
                  newQuestionOptionLabel && newQuestionOptionValue
                    ? "text-[#FFB155]"
                    : "text-gray-400"
                }`}
              >
                Add option
              </span>
            </div>

            <ul className="mt-2 px-3">
              {questionOptions.map((option, idx) => (
                <div className="flex flex-row" key={idx}>
                  <div className="flex w-1/2 flex-row justify-between">
                    <span className="w-full">
                      <span className="font-[Semibold] text-xs mr-1 text-gray-400">
                        Label:
                      </span>
                      {option.label}
                    </span>
                    <span className="w-full">
                      <span className="font-[Semibold] text-xs mr-1 text-gray-400">
                        Value:
                      </span>
                      {option.value}
                    </span>
                  </div>
                  <XMarkIcon
                    className="h-4 w-4 ml-auto cursor-pointer"
                    onClick={() => {
                      questionOptions.splice(idx, 1);
                      setQuestionOptions([...questionOptions]);
                    }}
                  />
                </div>
              ))}
            </ul>
          </div>
        );
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(
                      title,
                      order as number,
                      selectedActivity,
                      correctAnswer,
                      questionOptions,
                      selectedQuestionType,
                      defaultPoints as number
                    );
                  }}
                >
                  <div className="mb-2">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Question title
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="order"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Order
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        id="order"
                        name="order"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="answer"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Correct answer
                    </label>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        id="answer"
                        name="answer"
                        value={correctAnswer}
                        onChange={(e) => setCorrectAnswer(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="defaultPoints"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Default points
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        id="defaultPoints"
                        name="defaultPoints"
                        value={defaultPoints}
                        onChange={(e) => setDefaultPoints(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <Listbox
                      value={selectedActivity}
                      onChange={setSelectedActivity}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Activity
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {getSelectedActivity(selectedActivity)?.name ||
                                  "-"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {activities.map((activity) => (
                                  <Listbox.Option
                                    key={activity.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={activity.id}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {activity.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mt-2">
                    <Listbox
                      value={selectedQuestionType}
                      onChange={setSelectecQuestionType}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Question Type
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {selectedQuestionType || "-"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {questionTypes.map((questionType) => (
                                  <Listbox.Option
                                    key={questionType}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={questionType}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {questionType}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {renderQuestionType()}

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      onClick={onClose}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-[#FFB155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#FFB155] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
