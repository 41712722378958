import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import "./assets/fonts/PlusJakartaSans-Bold.ttf";
import "./assets/fonts/PlusJakartaSans-Regular.ttf";
import "./assets/fonts/PlusJakartaSans-SemiBold.ttf";

import App from "./App";
import { AuthProvider } from "./auth/AuthProvider";
import { SidebarProvider } from "./features/Home";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <SidebarProvider>
        <App />
      </SidebarProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
