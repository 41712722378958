import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  documentId,
} from "firebase/firestore";
import { omit } from "lodash";

import { db } from "../db/firebase";
import { Topic } from "../models/Topic";
import {
  SchoolClass,
  ClassSection,
  NewSchoolClass,
  NewClassSection,
} from "../models/SchoolClass";
import { User } from "../models/User";
import { topicConverter } from "../db/converters/topic";
import { userConverter } from "../db/converters/user";
import {
  schoolClassConverter,
  classSectionConverter,
} from "../db/converters/schoolClass";
import { useCallback } from "react";

class ClassNotFoundError extends Error {
  constructor() {
    super("Class not found.");
  }
}

export class ClassSectionNotFoundError extends Error {
  constructor() {
    super("Class section not found.");
  }
}
class CodeNotFoundError extends Error {
  constructor() {
    super("Class section code not found.");
  }
}

export default function useClassService() {
  const getClass = useCallback(
    async (schoolClassId: string): Promise<SchoolClass> => {
      const classDocRef = doc(db, `classes`, schoolClassId).withConverter(
        schoolClassConverter
      );

      const classDoc = await getDoc(classDocRef);

      if (classDoc.data() === undefined) throw new ClassNotFoundError();

      return classDoc.data()!;
    },
    []
  );

  const getClasses = useCallback(async (): Promise<SchoolClass[]> => {
    const classCollectionRef = query(collection(db, "classes")).withConverter(
      schoolClassConverter
    );

    const classCollection = await getDocs(classCollectionRef);

    return classCollection.docs.map((doc) => doc.data());
  }, []);

  const getClassSection = useCallback(
    async (classSectionId: string): Promise<ClassSection> => {
      const classSectionDocRef = doc(
        db,
        `classSections`,
        classSectionId
      ).withConverter(classSectionConverter);

      const classSectionDoc = await getDoc(classSectionDocRef);

      if (classSectionDoc.data() === undefined)
        throw new ClassSectionNotFoundError();

      return classSectionDoc.data()!;
    },
    []
  );

  const getClassSections = useCallback(async (): Promise<ClassSection[]> => {
    const classSectionsCollectionRef = query(
      collection(db, "classSections")
    ).withConverter(classSectionConverter);

    const classSectionsCollection = await getDocs(classSectionsCollectionRef);

    return classSectionsCollection.docs.map((doc) => doc.data());
  }, []);

  async function getClassSectionByCode(code: string): Promise<ClassSection> {
    const classSectionCollectionRef = query(
      collection(db, "classSections"),
      where("code", "==", code)
    ).withConverter(classSectionConverter);

    const classCollection = await getDocs(classSectionCollectionRef);

    if (classCollection.docs.length === 0) throw new CodeNotFoundError();

    return classCollection.docs[0].data();
  }

  async function getClassSectionTeachers(
    classSectionId: string
  ): Promise<User[]> {
    const teacherCollectionRef = query(
      collection(db, "users"),
      where("classSections", "array-contains", classSectionId)
    ).withConverter(userConverter);

    const teacherCollection = await getDocs(teacherCollectionRef);

    return teacherCollection.docs
      .map((doc) => doc.data())
      .filter((user) => user.userType === "teacher");
  }

  const getClassSectionTopics = useCallback(
    async (classSectionId: string): Promise<Topic[]> => {
      const topics: Topic[] = [];
      const classSection = await getClassSection(classSectionId);
      const schoolClass = await getClass(classSection.schoolClassId);

      if (schoolClass.topicsIds.length) {
        const topicsCollectionRef = query(
          collection(db, "topics"),
          where(documentId(), "in", schoolClass.topicsIds)
        ).withConverter(topicConverter);

        const topicsCollection = await getDocs(topicsCollectionRef);

        topics.push(...topicsCollection.docs.map((doc) => doc.data()));
      }

      if (classSection.topicsIds?.length) {
        const topicsCollectionRef = query(
          collection(db, "topics"),
          where(documentId(), "in", classSection.topicsIds)
        ).withConverter(topicConverter);

        const topicsCollection = await getDocs(topicsCollectionRef);

        topics.push(...topicsCollection.docs.map((doc) => doc.data()));
      }

      return topics;
    },
    [getClass, getClassSection]
  );

  async function createClass(
    schoolClass: NewSchoolClass
  ): Promise<SchoolClass> {
    const newClass = await addDoc(
      collection(db, `classes`).withConverter(schoolClassConverter),
      schoolClass
    );

    return {
      ...schoolClass,
      id: newClass.id,
    };
  }

  async function updateClass(
    classId: string,
    schoolClass: SchoolClass
  ): Promise<SchoolClass> {
    await updateDoc(doc(db, "classes", classId), {
      ...omit(schoolClass, "id"),
    });

    return schoolClass;
  }

  async function createClassSection(
    classSection: NewClassSection
  ): Promise<ClassSection> {
    const newClass = await addDoc(
      collection(db, `classSections`).withConverter(classSectionConverter),
      classSection
    );

    return {
      ...classSection,
      id: newClass.id,
    };
  }

  async function updateClassSection(
    classId: string,
    classSection: ClassSection
  ): Promise<ClassSection> {
    await updateDoc(doc(db, "classSections", classId), {
      ...omit(classSection, "id"),
    });

    return classSection;
  }

  return {
    getClass,
    getClasses,
    createClass,
    updateClass,
    getClassSection,
    getClassSections,
    createClassSection,
    updateClassSection,
    getClassSectionByCode,
    getClassSectionTopics,
    getClassSectionTeachers,
  };
}
