import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/20/solid";

import ClassModal from "./ClassModal";
import { SchoolClass, NewSchoolClass } from "../../../models/SchoolClass";
import useClassService from "../../../services/useClassService";
import ContentView from "../../../components/ContentView";
import { Topic } from "../../../models/Topic";
import useTopicService from "../../../services/useTopicService";

export default function Classes() {
  const navigate = useNavigate();
  const { getTopics } = useTopicService();
  const { getClass, getClasses, createClass, updateClass } = useClassService();

  const [openModal, setOpenModal] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [classes, setClasses] = useState<SchoolClass[]>([]);
  const [classs, setClass] = useState<SchoolClass | undefined>();

  useEffect(() => {
    init();

    async function init() {
      const topics = await getTopics();
      const classes = await getClasses();
      setTopics(topics);
      setClasses(classes);
    }
  }, [getTopics, getClasses]);

  function onCreateClass() {
    setClass(undefined);
    setOpenModal(true);
  }

  async function onEditClass(classId: string) {
    const classs = await getClass(classId);
    setClass(classs);
    setOpenModal(true);
  }

  async function onSaveClass(classname: string, topicsIds: string[]) {
    if (classs) {
      const updatedClass: SchoolClass = {
        ...classs,
        name: classname,
        topicsIds,
      };

      await updateClass(classs.id, updatedClass);
    } else {
      const createdClass: NewSchoolClass = {
        name: classname,
        topicsIds,
      };

      await createClass(createdClass);
    }

    const classes = await getClasses();

    setClasses(classes);
    setClass(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Classes"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateClass}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new class
        </span>
      }
    >
      <>
        <ul className="mt-3 p-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {classes.map((classs) => (
            <li
              key={classs.id}
              className="col-span-1 flex rounded-md shadow-sm"
            >
              <div className="flex flex-1 items-center justify-between rounded-r-md rounded-l-md border-l border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <span className="font-medium text-gray-900 hover:text-gray-600">
                    {classs.name}
                  </span>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={() => onEditClass(classs.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-red-400 hover:text-red-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button> */}
                </div>
              </div>
            </li>
          ))}
        </ul>
        {openModal && (
          <ClassModal
            topics={topics}
            classs={classs}
            open={openModal}
            onClose={() => {
              setClass(undefined);
              setOpenModal(false);
            }}
            onSubmit={onSaveClass}
          />
        )}
      </>
    </ContentView>
  );
}
