import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/20/solid";

import QuestionModal from "./QuestionModal";
import { Activity } from "../../../models/Activity";
import ContentView from "../../../components/ContentView";
import useQuestionService from "../../../services/useQuestionService";
import useActivityService from "../../../services/useActivityService";
import {
  NewQuestion,
  Question,
  QuestionOption,
  QuestionType,
} from "../../../models/Question";
import { omit } from "lodash";

export default function Questions() {
  const navigate = useNavigate();
  const { getActivities } = useActivityService();
  const { getQuestion, getQuestions, createQuestion, updateQuestion } =
    useQuestionService();

  const [openModal, setOpenModal] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [question, setQuestion] = useState<Question | undefined>();

  useEffect(() => {
    init();
    async function init() {
      const questions = await getQuestions();
      const activities = await getActivities();

      setQuestions(
        questions.sort((a, b) => {
          if (a.title! < b.title!) return -1;
          if (a.title! > b.title!) return 1;
          return 0;
        })
      );
      setActivities(activities);
    }
  }, [getActivities, getQuestions]);

  function onCreateQuestion() {
    setQuestion(undefined);
    setOpenModal(true);
  }

  async function onEditQuestion(questionId: string) {
    const question = await getQuestion(questionId);

    setQuestion(question);
    setOpenModal(true);
  }

  async function onDuplicateQuestion(questionId: string) {
    const question = await getQuestion(questionId);
    setQuestion({
      ...(omit(question, "id") as Question),
    });
    setOpenModal(true);
  }

  async function onSaveQuestion(
    title: string,
    order: number,
    activityId: string,
    correctAnswer: string,
    options: QuestionOption[],
    questionType: QuestionType,
    defaultPoints: number
  ) {
    if (question && question.id) {
      const updatedQuestion: Question = {
        ...question,
        title,
        order,
        activityId,
        correctAnswer,
        options,
        questionType,
        defaultPoints,
      };
      await updateQuestion(question.id, updatedQuestion);
    } else {
      const createdQuestion: NewQuestion = {
        title,
        order,
        activityId,
        correctAnswer,
        options,
        questionType,
        defaultPoints,
      };
      await createQuestion(createdQuestion);
    }
    const questions = await getQuestions();
    setQuestions(questions);
    setQuestion(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Questions"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateQuestion}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new question
        </span>
      }
    >
      <>
        <ul className="mt-3 p-2 grid grid-cols-1 gap-5 sm:gap-6">
          {questions.map((question) => (
            <li
              key={question.id}
              className="col-span-1 flex rounded-md shadow-sm"
            >
              <div className="flex flex-1 items-center justify-between rounded-r-md rounded-l-md border-l border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 w-11 text-ellipsis truncate px-4 py-2 text-sm">
                  <span className="font-medium text-gray-900 hover:text-gray-600 text-ellipsis">
                    {question.title}
                  </span>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={() => onEditQuestion(question.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={() => onDuplicateQuestion(question.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Open options</span>
                    <DocumentDuplicateIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {openModal && (
          <QuestionModal
            open={openModal}
            question={question}
            activities={activities}
            onClose={() => {
              setOpenModal(false);
              setQuestion(undefined);
            }}
            onSubmit={onSaveQuestion}
          />
        )}
      </>
    </ContentView>
  );
}
