import { Link } from "react-router-dom";
import { useRef, useState } from "react";

import "./styles.css";

import { useAuth } from "../../auth/AuthProvider";
import ToastError from "../../components/Notifications/ToastError";

const logo = require("./logo.png");

export default function Login() {
  const { login } = useAuth();
  const [error, setError] = useState("");

  const username = useRef("");
  const password = useRef("");

  async function signin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await login(username.current, password.current);
    } catch (err: any) {
      console.log(err);
      setError(err.message);
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 brandBackground">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-6 w-auto" src={logo} alt="Nextup" />
      </div>

      <div className="mt-10 sm:mx-auto w-[585px] bg-white rounded-2xl py-10 px-16">
        <h3 className="text-3xl font-['Bold'] text-center mb-10">Sign In</h3>
        <form className="space-y-6" onSubmit={signin}>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-['Semibold'] leading-4 text-[#9595A8]"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                required
                id="username"
                name="username"
                autoComplete="username"
                onChange={(e) => (username.current = e.target.value)}
                className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
              />
            </div>
          </div>

          <div className="mt-9">
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-['Semibold'] leading-4 text-[#9595A8]"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                required
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => (password.current = e.target.value)}
                className="bg-[#EFEFF3] h-12 py-4 text-sm  text-[#9595A8] rounded-md w-full border-none"
              />
            </div>
          </div>

          <div className="text-sm">
            <Link
              to={"/forgot-password"}
              className="text-sm font-['Semibold'] text-[#FFB155]"
            >
              Forgot password?
            </Link>
          </div>

          <div className="w-full h-px bg-[#E0E0EC]" />

          <div className="flex justify-center">
            <button
              type="submit"
              className="flex justify-center rounded-full bg-[#FFB155] px-28 py-3 text-base font-['Semibold'] leading-6 text-black shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Login
            </button>
          </div>
        </form>

        <p className="mt-6 text-center text-sm text-[#9595A8]">
          Not a member?{" "}
          <Link
            to={"/signup"}
            className="font-['Semibold'] leading-6 text-sm text-[#FFB155]"
          >
            Signup
          </Link>
        </p>
      </div>
      {error && <ToastError message={error} onClose={() => setError("")} />}
    </div>
  );
}
