import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCallback } from "react";

import { db } from "../db/firebase";

import { Teacher } from "../models/Teacher";
import { useAuth } from "../auth/AuthProvider";
import { FirebaseUser, StudentGrading, User } from "../models/User";
import { teacherConverter } from "../db/converters/teacher";
import { topicSectionSubmissionConverter } from "../db/converters/topic";
import { omit } from "lodash";

class TeacherNotFoundError extends Error {
  constructor() {
    super("Teacher not found.");
  }
}

export default function useTeacherService() {
  const { currentUser } = useAuth();

  async function getTeacher(teacherId: string): Promise<Teacher> {
    const teacherDocRef = doc(db, `teachers`, teacherId).withConverter(
      teacherConverter
    );

    const teacherDoc = await getDoc(teacherDocRef);

    if (teacherDoc.data() === undefined) throw new TeacherNotFoundError();

    return teacherDoc.data()!;
  }

  async function getTeacherByClassSection(
    classSectionId: string
  ): Promise<Teacher> {
    const teacherCollectionRef = query(
      collection(db, "teachers"),
      where("classSectionsIds", "array-contains", classSectionId)
    ).withConverter(teacherConverter);

    const teacherCollection = await getDocs(teacherCollectionRef);

    if (teacherCollection.docs.length === 0) throw new TeacherNotFoundError();

    return teacherCollection.docs[0].data();
  }

  const getTeachers = useCallback(async (): Promise<FirebaseUser[]> => {
    const teacherRef = query(
      collection(db, "users"),
      where("userType", "==", "teacher")
    );

    const teacherCollection = await getDocs(teacherRef);

    return teacherCollection.docs.map((doc) => ({
      id: doc.id,
      firstname: doc.data().firstname,
      lastname: doc.data().lastname,
      username: doc.data().username,
      classSections: doc.data().classSections,
      userType: doc.data().userType,
      password: doc.data().password,
    }));
  }, []);

  const getTeacherStudents = useCallback(async (): Promise<User[]> => {
    if (!currentUser?.classSections.length) return [];

    const teacherStudentsRef = query(
      collection(db, "users"),
      where("classSections", "array-contains-any", currentUser?.classSections),
      where("userType", "==", "student")
    );

    const teacherStudentsCollection = await getDocs(teacherStudentsRef);

    return teacherStudentsCollection.docs.map((doc) => ({
      id: doc.id,
      firstname: doc.data().firstname,
      lastname: doc.data().lastname,
      username: doc.data().username,
      classSections: doc.data().classSections,
      userType: doc.data().userType,
    }));
  }, [currentUser]);

  const getTeacherStudentsGrading = useCallback(
    async (topicSectionId: string): Promise<StudentGrading[]> => {
      const studentsGrading: StudentGrading[] = [];
      if (!currentUser?.classSections.length) return studentsGrading;

      const teacherStudentsRef = query(
        collection(db, "users"),
        where(
          "classSections",
          "array-contains-any",
          currentUser?.classSections
        ),
        where("userType", "==", "student")
      );

      const teacherStudentsCollection = await getDocs(teacherStudentsRef);

      for (let i = 0; i < teacherStudentsCollection.docs.length; i++) {
        const student = teacherStudentsCollection.docs[i];
        const studentGrading: StudentGrading = {
          id: student.id,
          firstname: student.data().firstname,
          lastname: student.data().lastname,
          username: student.data().username,
          classSections: student.data().classSections,
          userType: student.data().userType,
          isTopicSubmitted: false,
        };

        const topicSectionSubmissionDocRef = query(
          collection(db, `users/${student.id}/topicSectionSubmissions`),
          where("topicSectionId", "==", topicSectionId)
        ).withConverter(topicSectionSubmissionConverter);

        const result = await getDocs(topicSectionSubmissionDocRef);

        if (result.docs.length !== 0) {
          studentGrading.isTopicSubmitted = true;
          studentGrading.submissionDate = result.docs[0].data().datetime;
        }

        studentsGrading.push(studentGrading);
      }

      return studentsGrading;
    },
    [currentUser]
  );

  async function updateTeacher(
    teacherId: string,
    teacher: FirebaseUser
  ): Promise<FirebaseUser> {
    await updateDoc(doc(db, "users", teacherId), {
      ...omit(teacher, "id"),
    });

    return teacher;
  }

  return {
    getTeacher,
    getTeachers,
    updateTeacher,
    getTeacherStudents,
    getTeacherByClassSection,
    getTeacherStudentsGrading,
  };
}
