import {
  doc,
  query,
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { omit } from "lodash";
import { useCallback } from "react";

import { db } from "../db/firebase";
import { Score } from "../models/Score";
import { Response } from "../models/Response";
import { scoreConverter } from "../db/converters/score";
import { NewQuestion, Question } from "../models/Question";
import { questionConverter } from "../db/converters/question";
import { responseConverter } from "../db/converters/response";

class QuestionNotFoundError extends Error {
  constructor() {
    super("Question not found.");
  }
}

export default function useQuestionService() {
  async function getQuestion(questionId: string): Promise<Question> {
    const questionDocRef = doc(db, `questions`, questionId).withConverter(
      questionConverter
    );

    const questionDoc = await getDoc(questionDocRef);

    if (questionDoc.data() === undefined) throw new QuestionNotFoundError();

    return questionDoc.data()!;
  }

  async function getQuestions(): Promise<Question[]> {
    const questionCollectionRef = query(
      collection(db, "questions")
    ).withConverter(questionConverter);

    const questionCollection = await getDocs(questionCollectionRef);

    return questionCollection.docs.map((doc) => doc.data());
  }

  const getGradingQuestions = useCallback(
    async (activitiesIds: string[]): Promise<Question[]> => {
      const questionCollectionRef = query(
        collection(db, "questions"),
        where("activityId", "in", activitiesIds)
      ).withConverter(questionConverter);

      const questionCollection = await getDocs(questionCollectionRef);

      return questionCollection.docs.map((doc) => doc.data());
    },
    []
  );

  const getUserQuestionScores = useCallback(
    async (userId: string, questionId: string): Promise<Score | null> => {
      const collectionRef = query(
        collection(db, "users", userId, "scores"),
        where("questionId", "==", questionId)
      ).withConverter(scoreConverter);

      const result = await getDocs(collectionRef);

      if (result.docs.length === 0) return null;

      return result.docs[0].data();
    },
    []
  );

  const getUserQuestionResponse = useCallback(
    async (userId: string, questionId: string): Promise<Response | null> => {
      const collectionRef = query(
        collection(db, "users", userId, "responses"),
        where("questionId", "==", questionId)
      ).withConverter(responseConverter);

      const result = await getDocs(collectionRef);

      if (result.docs.length === 0) return null;

      return result.docs[0].data();
    },
    []
  );

  async function createQuestion(question: NewQuestion): Promise<Question> {
    const newQuestion = await addDoc(
      collection(db, `questions`).withConverter(questionConverter),
      question
    );

    return {
      ...question,
      id: newQuestion.id,
    };
  }

  async function updateQuestion(
    questionId: string,
    question: Question
  ): Promise<Question> {
    await updateDoc(doc(db, "questions", questionId), {
      ...omit(question, "id"),
    });

    return question;
  }

  return {
    getQuestion,
    getQuestions,
    createQuestion,
    updateQuestion,
    getGradingQuestions,
    getUserQuestionScores,
    getUserQuestionResponse,
  };
}
