import { useNavigate } from "react-router-dom";
import { useState, createContext, useContext, useEffect } from "react";

import { NewUser, User } from "../models/User";
import useAuthService from "../services/useAuthService";

export const USER_KEY = "user";

export const AuthContext = createContext<{
  isLoading: boolean;
  logout: () => Promise<void>;
  currentUser: User | undefined;
  login: (username: string, password: string) => Promise<void>;
  signup: (newUser: NewUser, sectionId: string) => Promise<void>;
} | null>(null);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const { signin, createAccount } = useAuthService();

  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | undefined>();

  useEffect(() => {
    setCurrentUser(retrieveCurrentUser());
    setIsLoading(false);
  }, []);

  async function logout() {
    setCurrentUser(undefined);
    clearCurrentUser();
    navigate("/login", { replace: true });
  }

  async function login(username: string, password: string) {
    const user = await signin(username, password);
    setCurrentUser(user);
    storeCurrentUser(user);
    navigate("/", { replace: true });
  }

  const signup = async (newUser: NewUser, sectionId: string) => {
    const user = await createAccount(newUser, sectionId);
    setCurrentUser(user);
    storeCurrentUser(user);
    navigate("/dashboard", { replace: true });
  };

  function storeCurrentUser(user: User) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  function retrieveCurrentUser(): User | undefined {
    const userJson = localStorage.getItem(USER_KEY);

    if (userJson) return JSON.parse(userJson);
  }

  function clearCurrentUser(): void {
    localStorage.removeItem(USER_KEY);
  }

  const values = {
    login,
    signup,
    logout,
    isLoading,
    currentUser,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error("useAuth has to be used within <AuthContext.Provider>");
  }

  return auth;
};
