import { FirestoreDataConverter } from "firebase/firestore";

import { SchoolClass, ClassSection } from "../../models/SchoolClass";

export const schoolClassConverter: FirestoreDataConverter<SchoolClass> = {
  toFirestore: (schoolClass) => ({
    name: schoolClass.name,
    topicsIds: schoolClass.topicsIds,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      topicsIds: data.topicsIds,
    };
  },
};

export const classSectionConverter: FirestoreDataConverter<ClassSection> = {
  toFirestore: (classSection) => ({
    name: classSection.name,
    code: classSection.code,
    schoolClassId: classSection.schoolClassId,
    topicsIds: classSection.topicsIds,
    releaseGrades: classSection.releaseGrades,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      code: data.code,
      schoolClassId: data.schoolClassId,
      topicsIds: data.topicsIds,
      releaseGrades: data.releaseGrades,
    };
  },
};
