import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import {
  Bars3Icon,
  Cog6ToothIcon,
  HomeIcon,
  XMarkIcon,
  AcademicCapIcon,
  Bars4Icon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/24/outline";
import {
  QuestionMarkCircleIcon,
  ArrowLeftOnRectangleIcon,
  ClipboardDocumentListIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

import { useAuth } from "../../../auth/AuthProvider";

const logo = require("../../../assets/images/logo.png");

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") return navigate("/admin-panel");
  }, [location, navigate]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            <li key={"dashboard"}>
                              <Link
                                to={"/admin-panel"}
                                className={classNames(
                                  location.pathname === "/admin-panel"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <HomeIcon
                                  className={classNames(
                                    location.pathname === "/admin-panel"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Admin Panel
                              </Link>
                            </li>
                            <li key={"teachers"}>
                              <Link
                                to={"/teachers"}
                                className={classNames(
                                  location.pathname === "/teachers"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <UsersIcon
                                  className={classNames(
                                    location.pathname === "/teachers"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Teachers
                              </Link>
                            </li>
                            <li key={"classes"}>
                              <Link
                                to={"/classes"}
                                className={classNames(
                                  location.pathname === "/classes"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <AcademicCapIcon
                                  className={classNames(
                                    location.pathname === "/classes"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Classes
                              </Link>
                            </li>
                            <li key={"class-sections"}>
                              <Link
                                to={"/class-sections"}
                                className={classNames(
                                  location.pathname === "/class-sections"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <Bars4Icon
                                  className={classNames(
                                    location.pathname === "/class-sections"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Class Sections
                              </Link>
                            </li>
                            <li key={"topics"}>
                              <Link
                                to={"/topics"}
                                className={classNames(
                                  location.pathname === "/topics"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <Bars3BottomLeftIcon
                                  className={classNames(
                                    location.pathname === "/topics"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Topics
                              </Link>
                            </li>
                            <li key={"questions"}>
                              <Link
                                to={"/questions"}
                                className={classNames(
                                  location.pathname === "/questions"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <QuestionMarkCircleIcon
                                  className={classNames(
                                    location.pathname === "/questions"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Questions
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <Link
                            to={"/settings"}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                              aria-hidden="true"
                            />
                            Settings
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col`}
          style={{
            width: "264px",
          }}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 bg-transparent pl-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <ArrowLeftOnRectangleIcon
                onClick={logout}
                className="h-8 w-auto text-white hover:cursor-pointer"
              />
            </div>
            <img src={logo} alt="logo" className="w-40 h-auto " />
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    <li
                      key={"dashboard"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/admin-panel"}
                        className={classNames(
                          location.pathname === "/admin-panel"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <HomeIcon
                          className={classNames(
                            location.pathname === "/admin-panel"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Admin Panel
                      </Link>
                      {location.pathname === "/admin-panel" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li key={"teachers"}>
                      <Link
                        to={"/teachers"}
                        className={classNames(
                          location.pathname === "/teachers"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <UsersIcon
                          className={classNames(
                            location.pathname === "/teachers"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Teachers
                      </Link>
                    </li>
                    <li key={"classes"} className="flex w-full justify-between">
                      <Link
                        to={"/classes"}
                        className={classNames(
                          location.pathname === "/classes"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <AcademicCapIcon
                          className={classNames(
                            location.pathname === "/classes"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Classes
                      </Link>
                      {location.pathname === "/classes" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li
                      key={"class-sections"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/class-sections"}
                        className={classNames(
                          location.pathname === "/class-sections"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <Bars4Icon
                          className={classNames(
                            location.pathname === "/class-sections"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Class Sections
                      </Link>
                      {location.pathname === "/class-sections" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li key={"topics"} className="flex w-full justify-between">
                      <Link
                        to={"/topics"}
                        className={classNames(
                          location.pathname === "/topics"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <Bars3BottomLeftIcon
                          className={classNames(
                            location.pathname === "/topics"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Topics
                      </Link>
                      {location.pathname === "/topics" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li
                      key={"topics-sections"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/topic-sections"}
                        className={classNames(
                          location.pathname === "/topic-sections"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <Bars3BottomLeftIcon
                          className={classNames(
                            location.pathname === "/topic-sections"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Topic Sections
                      </Link>
                      {location.pathname === "/topic-sections" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li
                      key={"activities"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/activities"}
                        className={classNames(
                          location.pathname === "/activities"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <ClipboardDocumentListIcon
                          className={classNames(
                            location.pathname === "/activities"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Activities
                      </Link>
                      {location.pathname === "/activities" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li
                      key={"questions"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/questions"}
                        className={classNames(
                          location.pathname === "/questions"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <QuestionMarkCircleIcon
                          className={classNames(
                            location.pathname === "/questions"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Questions
                      </Link>
                      {location.pathname === "/questions" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div
          className={`h-full relative`}
          style={{
            paddingLeft: "264px",
          }}
        >
          <button
            type="button"
            className="-m-2.5 p-2.5 text-black lg:hidden absolute bottom-3 left-3"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <main className="py-10 h-full">
            <div className="px-4 sm:px-6 lg:px-8 h-full">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
