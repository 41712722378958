import { useEffect, useState } from "react";

import { Score } from "../../models/Score";
import { Question } from "../../models/Question";
import { Response } from "../../models/Response";
import { Activity } from "../../models/Activity";
import useUserService from "../../services/useUserService";
import useTopicService from "../../services/useTopicService";
import useQuestionService from "../../services/useQuestionService";

export default function useQuestions(
  studentId: string,
  topicSectionId: string
) {
  const { getGradingQuestions } = useQuestionService();
  const { getTopicSectionActivities } = useTopicService();
  const { getUserResponses, getUserScores } = useUserService();

  const [activities, setActivities] = useState<Activity[]>([]);
  const [activityQuestions, setActivityQuestions] = useState<Question[]>([]);
  const [activityScores, setActivityScores] = useState<Partial<Score>[]>([]);
  const [activityAnswers, setActivityAnswers] = useState<Response[]>([]);

  useEffect(() => {
    getQuestions();

    async function getQuestions() {
      setActivityScores([]);
      setActivityAnswers([]);
      setActivityQuestions([]);

      const activityScores = [];
      const activityAnswers = [];
      const activities = await getTopicSectionActivities(topicSectionId!);
      const activityQuestions = await getGradingQuestions(
        activities
          .filter((activity) => activity.activityType === "blog-question")
          .map((activity) => activity.id)
      );
      const questionIds = activityQuestions.map((question) => question.id);

      const scores = await getUserScores(questionIds, studentId);
      const answers = await getUserResponses(questionIds, studentId);

      activityQuestions.sort(
        (questionA, questionB) => questionA.order - questionB.order
      );

      for (let i = 0; i < activityQuestions.length; i++) {
        const question = activityQuestions[i];
        const score =
          scores.find((score) => score.questionId === question.id) || null;
        const answer = answers.find(
          (answer) => answer.questionId === question.id
        );

        let partialScore: Partial<Score> = {
          comment: "",
          questionId: question.id,
          score: 0,
        };

        if (score) {
          partialScore.id = score?.id;
          partialScore.comment = score?.comment;
          partialScore.score = score?.score;
        }

        activityAnswers.push(answer!);
        activityScores.push(partialScore!);
      }

      setActivityScores(activityScores);
      setActivityAnswers(activityAnswers);
      setActivityQuestions(activityQuestions);
      setActivities(
        activities.filter(
          (activity) => activity.activityType === "blog-question"
        )
      );
    }
  }, [
    studentId,
    topicSectionId,
    getUserScores,
    getUserResponses,
    getGradingQuestions,
    getTopicSectionActivities,
  ]);

  return {
    activities,
    activityScores,
    activityAnswers,
    activityQuestions,
    setActivityScores,
  };
}
