import { Link } from "react-router-dom";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

import { TopicSection } from "../../../models/Topic";
import OpenBookIcon from "../../../components/Icons/OpenBookIcon";

interface TopicSectionGridItemProps {
  isSubmitted: boolean;
  topicSection: TopicSection;
  topicSectionNumber: number;
}

export default function TopicSectionGridItem({
  isSubmitted,
  topicSection,
  topicSectionNumber,
}: TopicSectionGridItemProps) {
  return (
    <Link to={`topicSections/${topicSection.id}`} className="rounded-xl">
      <img className="w-full" alt="" src={topicSection.headerImageUrl} />
      <div className="pt-3 pb-16 px-6 bg-[#E8CBFF] rounded-b-xl relative">
        <div className="flex items-center gap-3">
          <div className="w-7 h-7 bg-[#A785C9] rounded-3xl flex items-center justify-center">
            <OpenBookIcon />
          </div>
          <span className="text-[#A785C9] text-xs font-['Semibold'] font-semibold">{`Topic Section #${topicSectionNumber}`}</span>
        </div>
        <h3 className="text-base mt-4 font-semibold">{topicSection.name}</h3>
        {isSubmitted && (
          <CheckBadgeIcon className="h-7 w-7 text-green-600 absolute right-3 bottom-3" />
        )}
      </div>
    </Link>
  );
}
