import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

import "./styles.css";

import { NewUser, User } from "../../models/User";
import { useAuth } from "../../auth/AuthProvider";
import useClassService from "../../services/useClassService";
import { SchoolClass, ClassSection } from "../../models/SchoolClass";
import ToastError from "../../components/Notifications/ToastError";

const logo = require("./logo.png");

interface SelectedClass {
  class: SchoolClass;
  teachers: User[];
  classSection: ClassSection;
}

export default function Signup() {
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [selectedClass, setSelectedClass] = useState<SelectedClass>();

  async function onSignIn(newUser: NewUser) {
    try {
      await signup(newUser, selectedClass!.classSection.id);
    } catch (err: any) {
      console.log(err);
      setError(err.message);
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center brandBackground">
      {selectedClass ? (
        <SignupForm
          onSignIn={onSignIn}
          selectedClass={selectedClass}
          onBack={() => setSelectedClass(undefined)}
        />
      ) : (
        <ClassCodeForm onCodeEntered={setSelectedClass} />
      )}
      {error && <ToastError message={error} onClose={() => setError("")} />}
    </div>
  );
}

interface SignupFormProps {
  onBack: () => void;
  selectedClass: SelectedClass;
  onSignIn: (newUser: NewUser) => void;
}

function SignupForm({ onBack, onSignIn, selectedClass }: SignupFormProps) {
  const firstname = useRef("");
  const lastname = useRef("");
  const username = useRef("");
  const password = useRef("");

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const newUser: NewUser = {
      userType: "student",
      firstname: firstname.current,
      lastname: lastname.current,
      username: username.current,
      password: password.current,
    };

    onSignIn(newUser);
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 brandBackground">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-6 w-auto" src={logo} alt="Nextup" />
      </div>

      <div className="mt-10 sm:mx-auto w-[585px] bg-white rounded-2xl py-10 px-16">
        <div className="relative mb-10">
          <div
            onClick={onBack}
            className="w-8 h-8 top-1 absolute rounded-full border border-[#E0E0EC] flex items-center justify-center hover:cursor-pointer"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </div>
          <h3 className="text-3xl font-bold text-center">
            Create your account
          </h3>
        </div>
        <form className="space-y-6" onSubmit={onSubmit}>
          <div>
            <label
              htmlFor="firstname"
              className="block text-sm font-semibold leading-4 text-[#9595A8]"
            >
              Firstname
            </label>
            <div className="mt-2">
              <input
                required
                id="firstname"
                name="firstname"
                placeholder="Your firstname"
                onChange={(e) => (firstname.current = e.target.value)}
                className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="lastname"
              className="block text-sm font-semibold leading-4 text-[#9595A8]"
            >
              Lastname
            </label>
            <div className="mt-2">
              <input
                required
                id="lastname"
                name="lastname"
                placeholder="Your lastname"
                onChange={(e) => (lastname.current = e.target.value)}
                className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="username"
              className="block text-sm font-semibold leading-4 text-[#9595A8]"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                required
                id="username"
                name="username"
                placeholder="Your username"
                onChange={(e) => (username.current = e.target.value)}
                className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-semibold leading-4 text-[#9595A8]"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                required
                id="password"
                name="password"
                type="password"
                placeholder="Your password"
                onChange={(e) => (password.current = e.target.value)}
                className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
              />
            </div>
          </div>

          <div className="w-full h-px bg-[#E0E0EC]" />

          <div className="flex justify-center">
            <button
              type="submit"
              className="flex justify-center rounded-full bg-[#FFB155] px-28 py-3 text-base font-semibold leading-6 text-black shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Create account
            </button>
          </div>
        </form>

        <p className="mt-6 text-center text-sm text-[#9595A8]">
          Already a member?{" "}
          <Link
            to={"/login"}
            className="font-normal leading-6 text-sm text-[#FFB155]"
          >
            Signin
          </Link>
        </p>
      </div>
    </div>
  );
}

interface ClassCodeFormProps {
  onCodeEntered: (selectedClass: SelectedClass) => void;
}

function ClassCodeForm({ onCodeEntered }: ClassCodeFormProps) {
  const { getClass, getClassSectionByCode, getClassSectionTeachers } =
    useClassService();

  const [error, setError] = useState("");
  const [selectedClass, setSelectedClass] = useState<SelectedClass>();

  const code = useRef("");

  async function onNext(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const classSection = await getClassSectionByCode(code.current);
      const currentClass = await getClass(classSection.schoolClassId);
      const teachers = await getClassSectionTeachers(classSection.id);

      setSelectedClass({
        teachers,
        classSection,
        class: currentClass,
      });
    } catch (err: any) {
      console.log("err", err);
      setError(err.message);
    }
  }

  async function onConfirm() {
    onCodeEntered(selectedClass!);
  }

  return (
    <>
      {!selectedClass ? (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 brandBackground">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-6 w-auto" src={logo} alt="Nextup" />
          </div>
          <div className="mt-10 sm:mx-auto w-[585px] bg-white rounded-2xl py-10 px-16">
            <h3 className="text-3xl font-['Bold'] text-center mb-10">
              Sign Up
            </h3>
            <form className="space-y-6" onSubmit={onNext}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-['Semibold'] leading-4 text-[#9595A8]"
                >
                  Class section code
                </label>
                <div className="mt-2">
                  <input
                    required
                    id="code"
                    name="code"
                    placeholder="Your code"
                    onChange={(e) => (code.current = e.target.value)}
                    className="bg-[#EFEFF3] h-12 py-4 text-sm font-normal text-[#9595A8] rounded-md w-full border-none"
                  />
                </div>
              </div>

              <div className="w-full h-px bg-[#E0E0EC]" />

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="flex justify-center rounded-full bg-[#FFB155] px-28 py-3 text-base font-['Semibold'] leading-6 text-black shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  Next
                </button>
              </div>
            </form>

            <p className="mt-6 text-center text-sm text-[#9595A8]">
              Already a member?{" "}
              <Link
                to={"/login"}
                className="font-['Semibold'] leading-6 text-sm text-[#FFB155]"
              >
                Signin
              </Link>
            </p>
          </div>
        </div>
      ) : (
        <ClassDetails
          onBack={() => setSelectedClass(undefined)}
          onConfirm={onConfirm}
          selectedClass={selectedClass}
        />
      )}
      {error && <ToastError message={error} onClose={() => setError("")} />}
    </>
  );
}

interface ClassDetailsProps {
  onBack: () => void;
  onConfirm: () => void;
  selectedClass: SelectedClass;
}

function ClassDetails({ onBack, onConfirm, selectedClass }: ClassDetailsProps) {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 brandBackground">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-6 w-auto" src={logo} alt="Nextup" />
      </div>
      <div className="mt-10 sm:mx-auto w-[585px] bg-white rounded-2xl py-10 px-16">
        <div className="relative mb-10">
          <div
            onClick={onBack}
            className="w-8 h-8 top-1 absolute rounded-full border border-[#E0E0EC] flex items-center justify-center hover:cursor-pointer"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </div>
          <h3 className="text-3xl font-['Bold'] text-center">Class Details</h3>
        </div>
        <form className="space-y-6" onSubmit={onConfirm}>
          <div className="rounded-lg bg-[#EFEFF3]  border border-[#E0E0EC]">
            <div className="px-6 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-['Bold'] leading-6 text-gray-900">
                  {selectedClass.class.name}
                </h3>
                <span className="inline-flex items-center rounded-full bg-[#4AC27A] px-3 h-7 text-xs font-['Semibold'] text-white">
                  {selectedClass.classSection.code}
                </span>
              </div>
              <h5 className="mt-3 text-sm font-['Bold'] leading-6 text-[#9595A8]">
                {selectedClass.classSection.name}
              </h5>
            </div>

            <div className="w-full h-px bg-[#E0E0EC]" />

            <div className="px-6 pt-5 pb-6 flex flex-col gap-3">
              {selectedClass.teachers.map((teacher, idx) => (
                <div key={teacher.id} className="flex gap-3 items-center">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={
                      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    }
                    alt=""
                  />
                  <span className="font-['Semibold'] text-sm text-[#1A1A1D]">{`${teacher.firstname} ${teacher.lastname}`}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full h-px bg-[#E0E0EC]" />

          <div className="flex justify-center">
            <button
              type="submit"
              className="flex justify-center rounded-full bg-[#FFB155] px-28 py-3 text-base font-['Semibold'] leading-6 text-black shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
