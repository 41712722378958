import { Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider";

export default function RequireTeacherAuth({
  children,
}: {
  children: JSX.Element;
}) {
  const auth = useAuth();

  if (auth.currentUser?.userType !== "teacher") {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}
