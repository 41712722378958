import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Team } from "../../models/Team";
import { User } from "../../models/User";
import useTeamService from "../../services/useTeamService";
import TeamMembersGrid from "./TeamMembersGrid";

export default function TeamDashboard() {
  const { teamId } = useParams<{ teamId: string }>();
  const { getTeam, getTeamMembers } = useTeamService();
  const [, setTeam] = useState<Team>();
  const [teamMembers, setTeamMembers] = useState<User[]>([]);

  useEffect(() => {
    init();

    async function init() {
      const team = await getTeam(teamId!);
      const teamMembers = await getTeamMembers(team.users);

      setTeam(team);
      setTeamMembers(teamMembers);
    }
  }, [teamId, getTeam, getTeamMembers]);

  return (
    <div>
      <TeamMembersGrid members={teamMembers} />
    </div>
  );
}
