import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TeacherModal from "./TeachersModal";
import { FirebaseUser, NewUser } from "../../../models/User";
import ContentView from "../../../components/ContentView";
import { ClassSection } from "../../../models/SchoolClass";
import useClassService from "../../../services/useClassService";
import useTeacherService from "../../../services/useTeacherService";
import useAuthService from "../../../services/useAuthService";

export default function Teachers() {
  const navigate = useNavigate();
  const { createAccount } = useAuthService();
  const { getClassSections } = useClassService();
  const { getTeachers, updateTeacher } = useTeacherService();

  const [openModal, setOpenModal] = useState(false);
  const [teachers, setTeachers] = useState<FirebaseUser[]>([]);
  const [classSections, setClassSections] = useState<ClassSection[]>([]);
  const [currentTeacher, setCurrentTeacher] = useState<
    FirebaseUser | undefined
  >();

  useEffect(() => {
    init();

    async function init() {
      const teachers = await getTeachers();
      const classSections = await getClassSections();
      setTeachers(teachers);
      setClassSections(classSections);
    }
  }, [getTeachers, getClassSections]);

  function onCreateTeacher() {
    setOpenModal(true);
    setCurrentTeacher(undefined);
  }

  function onEditTeacher(teacher: FirebaseUser) {
    setOpenModal(true);
    setCurrentTeacher(teacher);
  }

  async function onSaveTeacher(
    firstname: string,
    lastname: string,
    classSectionId: string,
    username: string,
    password: string
  ) {
    if (currentTeacher) {
      const updatedTeacher: FirebaseUser = {
        ...currentTeacher,
        firstname,
        lastname,
        classSections: [classSectionId],
        password,
      };
      await updateTeacher(currentTeacher.id, updatedTeacher);
    } else {
      const createdTeacher: NewUser = {
        firstname,
        lastname,
        password,
        username,
        userType: "teacher",
      };
      await createAccount(createdTeacher, classSectionId);
    }
    const teachers = await getTeachers();
    setTeachers(teachers);
    setCurrentTeacher(undefined);
    setOpenModal(false);
  }

  return (
    <ContentView
      title="Teachers"
      onBack={() => navigate("/admin-panel")}
      customContent={
        <span
          onClick={onCreateTeacher}
          className={`cursor-pointer flex items-center font-[Semibold] text-sm gap-3 mr-56 text-[#FFB155]`}
        >
          Create new teacher
        </span>
      }
    >
      <>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-[Semibold] text-gray-900 sm:pl-6"
              >
                First Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-[Semibold] text-gray-900"
              >
                Last Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-[Semibold] text-gray-900"
              >
                Username
              </th>

              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {teachers.map((teacher) => (
              <tr key={teacher.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-[Regular] text-gray-900 sm:pl-6">
                  {teacher.firstname}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-[Regular]">
                  {teacher.lastname}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-[Regular]">
                  {teacher.username}
                </td>

                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 font-[Regular]">
                  <span
                    onClick={() => onEditTeacher(teacher)}
                    className="text-[#FFB155] hover:text-[#FFB155] cursor-pointer"
                  >
                    Edit
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {openModal && (
          <TeacherModal
            open={openModal}
            teacher={currentTeacher}
            classSections={classSections}
            onClose={() => {
              setOpenModal(false);
              setCurrentTeacher(undefined);
            }}
            onSubmit={onSaveTeacher}
          />
        )}
      </>
    </ContentView>
  );
}
