import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Topic,
  TopicSection,
  TopicSectionSubmission,
} from "../../../models/Topic";
import ContentView from "../../../components/ContentView";
import TopicSectionGridItem from "./TopicSectionGridItem";
import useTopicService from "../../../services/useTopicService";

export default function TopicSectionsGrid() {
  const navigate = useNavigate();
  const { topicId } = useParams<{ topicId: string }>();
  const { getTopic, getTopicSections, getTopicSectionSubmissions } =
    useTopicService();

  const [, setTopic] = useState<Topic>();
  const [topicSections, setTopicSections] = useState<TopicSection[]>([]);
  const [topicSectionSubmissions, setTopicSectionSubmissions] = useState<
    TopicSectionSubmission[]
  >([]);

  useEffect(() => {
    init();

    async function init() {
      const topic = await getTopic(topicId!);
      const topicSections = await getTopicSections(topicId!);
      const topicSectionSubmissions = await getTopicSectionSubmissions(
        topicSections.map((topicSection) => topicSection.id)
      );
      setTopic(topic);

      setTopicSections(topicSections.sort((a, b) => a.order - b.order));
      setTopicSectionSubmissions(topicSectionSubmissions);
    }
  }, [topicId, getTopic, getTopicSections, getTopicSectionSubmissions]);

  function isSubmitted(topicSection: TopicSection) {
    const topicSectionSubmission = topicSectionSubmissions.find(
      (topicSectionSubmission) =>
        topicSectionSubmission.topicSectionId === topicSection.id
    );

    return !!topicSectionSubmission;
  }

  return (
    <ContentView onBack={() => navigate(-1)} title="Topic Sections">
      <ul className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
        {topicSections.map((topicSection, idx) => (
          <TopicSectionGridItem
            key={topicSection.id}
            topicSection={topicSection}
            topicSectionNumber={idx + 1}
            isSubmitted={isSubmitted(topicSection)}
          />
        ))}
      </ul>
    </ContentView>
  );
}
