import { FirestoreDataConverter } from "firebase/firestore";

import { Score } from "../../models/Score";

export const scoreConverter: FirestoreDataConverter<Score> = {
  toFirestore: (score) => ({
    comment: score.comment,
    datetime: score.datetime,
    questionId: score.questionId,
    score: score.score,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      comment: data.comment,
      datetime: data.datetiem,
      questionId: data.questionId,
      score: data.score,
    };
  },
};
