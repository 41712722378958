import { Route, Routes } from "react-router-dom";

import Home from "./features/Home";
import Login from "./features/Auth/Login";
import Signup from "./features/Auth/Signup";
import Dashboard from "./features/Dashboard";
import RequireAuth from "./auth/RequireAuth";
import { useAuth } from "./auth/AuthProvider";
import AdminPanel from "./features/AdminPanel";
import TeamDashboard from "./features/TeamDashboard";
import TopicsGrid from "./features/Topics/TopicsGrid";
import Questions from "./features/Questions/Questions";
import StudentsRoaster from "./features/StudentsRoaster";
import Activities from "./features/Activities/Activities";
import RequireTeacherAuth from "./auth/RequireTeacherAuth";
import LoadingScreen from "./components/Loading/LoadingScreen";
import GradingsTopicsGrid from "./features/Gradings/GradingsTopicsGrid";
import TopicSectionsGrid from "./features/Topics/TopicSections/TopicSectionsGrid";
import GradingsTopicSectionsGrid from "./features/Gradings/TopicSections/GradingsTopicSectionsGrid";
import TeacherStudents from "./features/Gradings/TeacherStudents";
import StudentGrading from "./features/Gradings/StudentGrading";
import MyIdeas from "./features/MyIdeas";
import BusinessPlan from "./features/BusinessPlan";
import BlogTableDnDActivity from "./features/Activities/ActivityTypeTemplate/BlogTableDnDActivity";

function App() {
  const { isLoading, currentUser } = useAuth();

  if (isLoading) return <LoadingScreen />;

  if (currentUser && currentUser.userType === "admin") return <AdminPanel />;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-ideas" element={<MyIdeas />} />
        <Route path="/business-plan" element={<BusinessPlan />} />
        <Route
          path="/class-sections/:classSectionId/topics"
          element={<TopicsGrid />}
        />
        <Route
          path="/class-sections/:classSectionId/topics/:topicId"
          element={<TopicSectionsGrid />}
        />
        <Route
          path="/class-sections/:classSectionId/topics/:topicId/topicSections/:topicSectionId"
          element={<Activities />}
        />
        <Route
          path="/class-sections/:classSectionId/topics/:topicId/topicSections/:topicSectionId/activity/:activityId"
          element={<Questions />}
        />
        <Route
          path="/teams/:teamId/team-dashboard"
          element={<TeamDashboard />}
        />
        <Route
          path="/students-roster"
          element={
            <RequireTeacherAuth>
              <StudentsRoaster />
            </RequireTeacherAuth>
          }
        />
        <Route
          path="/gradings"
          element={
            <RequireTeacherAuth>
              <GradingsTopicsGrid />
            </RequireTeacherAuth>
          }
        />
        <Route
          path="/gradings/:topicId"
          element={
            <RequireTeacherAuth>
              <GradingsTopicSectionsGrid />
            </RequireTeacherAuth>
          }
        />
        <Route
          path="/gradings/:topicId/topicSections/:topicSectionId"
          element={
            <RequireTeacherAuth>
              <TeacherStudents />
            </RequireTeacherAuth>
          }
        />
        <Route
          path="/gradings/:topicId/topicSections/:topicSectionId/student/:studentId"
          element={
            <RequireTeacherAuth>
              <StudentGrading />
            </RequireTeacherAuth>
          }
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
}

export default App;
