import { Activity } from "../../../models/Activity";
import { NewQuestion, QuestionType } from "../../../models/Question";
import useQuestionService from "../../../services/useQuestionService";

type InputSize = "small" | "medium" | "large";

export default function useActivities() {
  const { createQuestion } = useQuestionService();

  async function createFillBlankActivityQuestions(
    activity: Activity
  ): Promise<string> {
    const html = new DOMParser().parseFromString(
      activity.contentHtml as string,
      "text/html"
    );
    const blanksToFill = Array.from(
      html.getElementsByClassName("question_as_blank")
    ) as HTMLElement[];

    for (let i = 0; i < blanksToFill.length; i++) {
      const blankToFill = blanksToFill[i];
      const correctAnswer = blankToFill.dataset["correct"];
      const questionType = blankToFill.dataset["type"] as QuestionType;
      const size = blankToFill.dataset["size"] as InputSize | undefined;

      const newQuestion: NewQuestion = {
        order: i + 1,
        activityId: activity.id,
        questionType,
        correctAnswer,
        defaultPoints: 10,
        title: `${activity.name} - Question ${i + 1}`,
      };

      const createdQuestion = await createQuestion(newQuestion);

      const id = createdQuestion.id;

      const input = blankToFill.getElementsByTagName("input")[0];
      input.id = id;
      input.classList.add("fillBlank");
      input.style.width = getInputSize(size);

      blankToFill.removeAttribute("data-size");
      blankToFill.removeAttribute("data-type");
      blankToFill.removeAttribute("data-correct");
    }

    return html.getElementsByTagName("body")[0].innerHTML;
  }

  function getInputSize(size: InputSize | undefined): string {
    if (size === "small") return "150px";
    if (size === "large") return "450px";

    return "300px";
  }

  return {
    createFillBlankActivityQuestions,
  };
}
