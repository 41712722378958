import { omit } from "lodash";
import {
  query,
  collection,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import { db } from "../db/firebase";
import { FirebaseUser, NewUser, User } from "../models/User";

class UserNotFoundError extends Error {
  constructor() {
    super("User does not exist.");
  }
}

class WrongPasswordError extends Error {
  constructor() {
    super("The password is incorrect.");
  }
}

class UserAlreadyExistsError extends Error {
  constructor() {
    super("User already exists.");
  }
}

export default function useAuthService() {
  async function signin(username: string, password: string): Promise<User> {
    const user = await getUser(username);
    console.log(user);

    if (!user) throw new UserNotFoundError();

    if (user && user.password !== password) throw new WrongPasswordError();

    return {
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      classSections: user.classSections,
      userType: user.userType,
    };
  }

  async function createAccount(
    newUser: NewUser,
    sectionId: string
  ): Promise<User> {
    const existingUser = await doesUserExist(newUser.username);

    if (existingUser) throw new UserAlreadyExistsError();

    const newAccount = await addDoc(collection(db, "users"), {
      ...newUser,
      classSections: [sectionId],
    });

    const user = omit<NewUser, keyof NewUser>(newUser, ["password"]) as User;

    return {
      ...user,
      id: newAccount.id,
      classSections: [sectionId],
    };
  }

  async function restorePassword(
    userId: string,
    newPassword: string
  ): Promise<void> {
    await updateDoc(doc(db, "users", userId), {
      password: newPassword,
    });
  }

  async function getUser(username: string): Promise<FirebaseUser | null> {
    const userCollectionRef = query(
      collection(db, "users"),
      where("username", "==", username)
    );

    const user = await getDocs(userCollectionRef);

    if (user.docs.length > 0)
      return {
        ...(user.docs[0].data() as FirebaseUser),
        id: user.docs[0].id,
      };

    return null;
  }

  async function doesUserExist(username: string): Promise<boolean> {
    const user = await getUser(username);

    return !!user;
  }

  return {
    signin,
    createAccount,
    restorePassword,
  };
}
