import { useState } from "react";

import { User } from "../../models/User";
import ResetPasswordModal from "./ResetPasswordModal";
import useAuthService from "../../services/useAuthService";

interface StudentsTableProps {
  students: User[];
}

export default function StudentsTable({ students }: StudentsTableProps) {
  const { restorePassword } = useAuthService();
  const [currentStudent, setCurrentStudent] = useState("");

  async function onSubmit(newPassword: string) {
    if (currentStudent) {
      await restorePassword(currentStudent, newPassword);

      setCurrentStudent("");
    }
  }

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-[Semibold] text-gray-900 sm:pl-6"
            >
              First Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-[Semibold] text-gray-900"
            >
              Last Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-[Semibold] text-gray-900"
            >
              Username
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {students
            .sort((studentA, studentB) =>
              studentA.lastname > studentB.lastname ? 1 : -1
            )
            .map((student) => (
              <tr key={student.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-[Regular] text-gray-900 sm:pl-6">
                  {student.firstname}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-[Regular]">
                  {student.lastname}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-[Regular]">
                  {student.username}
                </td>

                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 font-[Regular]">
                  <span
                    onClick={() => setCurrentStudent(student.id)}
                    className="text-[#FFB155] hover:text-[#FFB155] cursor-pointer"
                  >
                    Edit
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {currentStudent && (
        <ResetPasswordModal
          open={!!currentStudent}
          onSubmit={onSubmit}
          onClose={() => setCurrentStudent("")}
        />
      )}
    </div>
  );
}
