import { useNavigate } from "react-router-dom";
import ContentView from "../../components/ContentView";

export default function MyIdeas() {
  const navigate = useNavigate();
  return (
    <ContentView title="My Ideas" onBack={() => navigate(-1)}>
      <div className="h-full flex flex-col">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-4">
          Coming Soon
        </h1>
      </div>
    </ContentView>
  );
}
