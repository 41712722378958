import { Fragment } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  ChevronUpDownIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";

import { Activity, ActivityType } from "../../../models/Activity";
import { Topic, TopicSection } from "../../../models/Topic";
import { useState } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface ActivityModalProps {
  open: boolean;
  topics: Topic[];
  activity?: Activity;
  onClose: () => void;
  topicSections: TopicSection[];
  onSubmit: (
    name: string,
    order: number,
    activityType: ActivityType,
    subtitle: string,
    topicSectionId: string,
    heroImageUrl: string,
    nextButtonTitle: string,
    teachersGuide: string,
    contentHtml: string,
    tableColumns: string[]
  ) => void;
}

export default function ActivityModal({
  open,
  topics,
  onClose,
  onSubmit,
  activity,
  topicSections,
}: ActivityModalProps) {
  const activityTypes: ActivityType[] = [
    "blog",
    "blog-question",
    "table-blog-questions",
    "fullimage",
    "fill-blanks",
    "table-blog-dnd-questions",
  ];
  const [name, setName] = useState(activity?.name || "");
  const [order, setOrder] = useState(activity?.order || "");
  const [subtitle, setSubtitle] = useState(activity?.subtitle || "");
  const [teachersGuide, setTeachersGuide] = useState(
    activity?.teachersGuide || ""
  );
  const [imageUrl, setImageUrl] = useState(activity?.heroImageUrl || "");
  const [nextButtonTitle, setNextButtonTitle] = useState(
    activity?.nextButtonTitle || ""
  );
  const [selectedTopic, setSelectedTopic] = useState<string>("");
  const [selectedTopicSection, setSelectedTopicSection] = useState<string>(
    activity?.topicSectionId || ""
  );
  const [selectedActivityType, setSelectecActivityType] = useState<string>(
    activity?.activityType || ""
  );
  const [tableColumns, setTableColumns] = useState<string[]>(
    activity?.tableColumns || []
  );
  const [contentHtml, setContentHtml] = useState(activity?.contentHtml || "");
  const [newTableColumnName, setNewTableColumnName] = useState("");

  function getSelectedTopic(topicId: string): Topic {
    return topics.find((topic) => topic.id === topicId) as Topic;
  }

  function getSelectedTopicSection(topicSectionId: string): TopicSection {
    return topicSections.find(
      (topicSection) => topicSection.id === topicSectionId
    ) as TopicSection;
  }

  const filteredTopicSections = selectedTopic
    ? topicSections.filter(
        (topicSection) => topicSection.topicId === selectedTopic
      )
    : topicSections;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(
                      name,
                      order as number,
                      selectedActivityType as ActivityType,
                      subtitle,
                      selectedTopicSection,
                      imageUrl,
                      nextButtonTitle,
                      teachersGuide,
                      contentHtml as string,
                      tableColumns
                    );
                  }}
                >
                  <div className="mb-2">
                    <label
                      htmlFor="namr"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Activity name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <Listbox
                      value={selectedActivityType}
                      onChange={setSelectecActivityType}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Activity Type
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {selectedActivityType || "-"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {activityTypes.map((activityType) => (
                                  <Listbox.Option
                                    key={activityType}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={activityType}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {activityType}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  {selectedActivityType === "table-blog-questions" ||
                  selectedActivityType === "table-blog-dnd-questions" ? (
                    <div className="mt-2 p-2 bg-gray-50 rounded-md">
                      <div className="flex flex-row gap-4">
                        <div className="flex flex-row gap-4 items-center">
                          <label
                            htmlFor="tableColumn"
                            className="block text-xs font-medium leading-6 text-gray-900"
                          >
                            Table column
                          </label>
                          <div className="">
                            <input
                              type="text"
                              id="tableColumn"
                              name="tableColumn"
                              value={newTableColumnName}
                              onChange={(e) =>
                                setNewTableColumnName(e.target.value)
                              }
                              className="block w-full text-xs rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                            />
                          </div>
                        </div>

                        <span
                          onClick={() => {
                            if (newTableColumnName) {
                              tableColumns.push(newTableColumnName);
                              setNewTableColumnName("");
                            }
                          }}
                          className={`${
                            newTableColumnName ? "cursor-pointer" : ""
                          } flex items-center font-[Semibold] text-sm gap-3 mr-56 ${
                            newTableColumnName
                              ? "text-[#FFB155]"
                              : "text-gray-400"
                          }`}
                        >
                          Add column
                        </span>
                      </div>
                      <ul className="mt-2 px-3">
                        {tableColumns.map((column, idx) => (
                          <div className="flex flex-row" key={idx}>
                            <div className="flex w-1/2 flex-row justify-between">
                              <span className="w-full">
                                <span className="font-[Semibold] text-xs mr-1 text-gray-400">
                                  Column:
                                </span>
                                {column}
                              </span>
                            </div>
                            <XMarkIcon
                              className="h-4 w-4 ml-auto cursor-pointer"
                              onClick={() => {
                                tableColumns.splice(idx, 1);
                                setTableColumns([...tableColumns]);
                              }}
                            />
                          </div>
                        ))}
                      </ul>
                    </div>
                  ) : null}

                  <div className="mt-2">
                    <Listbox value={selectedTopic} onChange={setSelectedTopic}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Topic
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {getSelectedTopic(selectedTopic)?.name || "-"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {topics.map((topic) => (
                                  <Listbox.Option
                                    key={topic.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={topic.id}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {topic.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mt-2">
                    <Listbox
                      value={selectedTopicSection}
                      onChange={setSelectedTopicSection}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Topic Section
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {getSelectedTopicSection(selectedTopicSection)
                                  ?.name || "-"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredTopicSections.map((topicSection) => (
                                  <Listbox.Option
                                    key={topicSection.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={topicSection.id}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {topicSection.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="order"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Order
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        id="order"
                        name="order"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="subtitle"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Subtitle (optional)
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="subtitle"
                        name="subtitle"
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="nextButtonTitle"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Next button title (optional)
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="nextButtonTitle"
                        name="nextButtonTitle"
                        value={nextButtonTitle}
                        onChange={(e) => setNextButtonTitle(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="teacherGuide"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Teachers Guide (optional)
                    </label>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        id="teacherGuide"
                        name="teacherGuide"
                        value={teachersGuide}
                        onChange={(e) => setTeachersGuide(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="contentHtml"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Content
                    </label>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        id="contentHtml"
                        name="contentHtml"
                        value={contentHtml.toString()}
                        onChange={(e) => setContentHtml(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="imageUrl"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Activity image url
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="imageUrl"
                        name="imageUrl"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      onClick={onClose}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-[#FFB155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#FFB155] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
