/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fragment,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";
import {
  AcademicCapIcon,
  Bars3Icon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/20/solid";
import {
  HomeIcon,
  XMarkIcon,
  UsersIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import { Team } from "../../models/Team";
import { useAuth } from "../../auth/AuthProvider";
import useUserService from "../../services/useUserService";

const logo = require("../../assets/images/logo.png");

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SidebarContext = createContext<{
  isCollapse: boolean;
  setIsCollapse: (collapse: boolean) => void;
} | null>(null);

export const SidebarProvider = ({ children }: { children: JSX.Element }) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const values = {
    isCollapse,
    setIsCollapse,
  };

  return (
    <SidebarContext.Provider value={values}>{children}</SidebarContext.Provider>
  );
};

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isCollapse } = useSidebar();
  const { logout, currentUser } = useAuth();
  const { getUserTeams } = useUserService();
  const [teams, setTeams] = useState<Team[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    async function init() {
      const teams = await getUserTeams();
      setTeams(teams);
    }

    init();

    if (location.pathname === "/") return navigate("/dashboard");
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                      /> */}
                      <ArrowLeftOnRectangleIcon className="h-8 w-auto text-white" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            <li key={"dashboard"}>
                              <Link
                                to={"/dashboard"}
                                className={classNames(
                                  location.pathname === "/dashboard"
                                    ? "bg-indigo-700 text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <HomeIcon
                                  className={classNames(
                                    location.pathname === "/dashboard"
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Dashboard
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-indigo-200">
                            Your teams
                          </div>
                          <ul className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <Link
                                  to={`/teams/${team.id}/team-dashboard`}
                                  className={classNames(
                                    location.pathname.includes(team.id)
                                      ? "bg-indigo-700 text-white"
                                      : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">
                                    {team.name[0]}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <Link
                            to={"/settings"}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                              aria-hidden="true"
                            />
                            Settings
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          //lg:w-[${isCollapse ? "62px" : "264px"}]
          className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col`}
          style={{
            width: isCollapse ? "62px" : "264px",
          }}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 bg-transparent pl-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <ArrowLeftOnRectangleIcon
                onClick={logout}
                className="h-8 w-auto text-white hover:cursor-pointer"
              />
            </div>
            {isCollapse ? null : (
              <img src={logo} alt="logo" className="w-40 h-auto " />
            )}
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    <li
                      key={"dashboard"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/dashboard"}
                        className={classNames(
                          location.pathname === "/dashboard" ||
                            location.pathname.includes("class-sections")
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <HomeIcon
                          className={classNames(
                            location.pathname === "/dashboard" ||
                              location.pathname.includes("class-sections")
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {isCollapse ? "" : "Practice"}
                      </Link>
                      {location.pathname === "/dashboard" ||
                      location.pathname.includes("class-sections") ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li
                      key={"my-ideas"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/my-ideas"}
                        className={classNames(
                          location.pathname === "/my-ideas"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <ChatBubbleOvalLeftEllipsisIcon
                          className={classNames(
                            location.pathname === "/my-ideas"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {isCollapse ? "" : "My Ideas"}
                      </Link>
                      {location.pathname === "/my-ideas" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                    <li
                      key={"business-plan"}
                      className="flex w-full justify-between"
                    >
                      <Link
                        to={"/business-plan"}
                        className={classNames(
                          location.pathname === "/business-plan"
                            ? " text-[#FFB155]"
                            : "text-[#FFB155] opacity-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <BriefcaseIcon
                          className={classNames(
                            location.pathname === "/business-plan"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {isCollapse ? "" : "Business Plan"}
                      </Link>
                      {location.pathname === "/business-plan" ? (
                        <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                      ) : null}
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className="-mx-2 space-y-1">
                    {currentUser?.userType === "teacher" ? (
                      <li
                        key={"studentsRoster"}
                        className="flex w-full justify-between"
                      >
                        <Link
                          to={"/students-roster"}
                          className={classNames(
                            location.pathname === "/students-roster"
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <UsersIcon
                            className={classNames(
                              location.pathname === "/students-roster"
                                ? " text-[#FFB155]"
                                : "text-[#FFB155] opacity-50",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {isCollapse ? "" : "Student Roster"}
                        </Link>
                        {location.pathname === "/students-roster" ? (
                          <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                        ) : null}
                      </li>
                    ) : null}
                    {currentUser?.userType === "teacher" ? (
                      <li
                        key={"gradings"}
                        className="flex w-full justify-between"
                      >
                        <Link
                          to={"/gradings"}
                          className={classNames(
                            location.pathname.includes("/gradings")
                              ? " text-[#FFB155]"
                              : "text-[#FFB155] opacity-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <AcademicCapIcon
                            className={classNames(
                              location.pathname.includes("/gradings")
                                ? " text-[#FFB155]"
                                : "text-[#FFB155] opacity-50",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {isCollapse ? "" : "Grading"}
                        </Link>
                        {location.pathname === "/gradings" ? (
                          <div className="bg-[#FFB155] rounded-l-md w-[4px] ml-auto" />
                        ) : null}
                      </li>
                    ) : null}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div
          //lg:pl-[${isCollapse ? "62px" : "264px"}]
          className={`h-full relative`}
          style={{
            paddingLeft: isCollapse ? "62px" : "264px",
          }}
        >
          <button
            type="button"
            className="-m-2.5 p-2.5 text-black lg:hidden absolute bottom-3 left-3"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          <main className="py-2 h-full">
            <div className="px-2 sm:px-2 lg:px-2 h-full">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export const useSidebar = () => {
  const sidebar = useContext(SidebarContext);

  if (!sidebar) {
    throw new Error(
      "useSidebar has to be used within <SidebarContext.Provider>"
    );
  }

  return sidebar;
};
