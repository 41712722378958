import moment from "moment";
import { Fragment, useState } from "react";
import { Topic, TopicSection } from "../../../../models/Topic";
import { Transition, Dialog, Listbox } from "@headlessui/react";
import {
  XMarkIcon,
  ChevronUpDownIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface TopicSectionModalProps {
  open: boolean;
  topics: Topic[];
  onClose: () => void;
  onSubmit: (
    name: string,
    order: number,
    topicId: string,
    headerImageUrl: string,
    submissionDeadline: string,
    dueDate: string,
    published: boolean
  ) => void;
  topicSection?: TopicSection;
}

export default function TopicSectionModal({
  open,
  topics,
  onClose,
  onSubmit,
  topicSection,
}: TopicSectionModalProps) {
  const [name, setName] = useState(topicSection?.name || "");
  const [selectedTopic, setSelectedTopic] = useState<string>(
    topicSection?.topicId || ""
  );
  const [order, setOrder] = useState(topicSection?.order || "");
  const [submissionDeadline, setSubmissionDeadline] = useState(
    moment(topicSection?.submissionDeadline.toDate()).format(
      "YYYY-MM-DDTHH:mm"
    ) || ""
  );
  const [dueDate, setDuedate] = useState(
    moment(topicSection?.dueDate.toDate()).format("YYYY-MM-DDTHH:mm") || ""
  );
  const [imageUrl, setImageUrl] = useState(topicSection?.headerImageUrl || "");
  const [published, setPublished] = useState(topicSection?.published || false);

  function getSelectedTopic(topicId: string): Topic {
    return topics.find((topic) => topic.id === topicId) as Topic;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(
                      name,
                      order as number,
                      selectedTopic,
                      imageUrl,
                      submissionDeadline,
                      dueDate,
                      published
                    );
                  }}
                >
                  <div className="mb-2">
                    <label
                      htmlFor="topicSectionName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Topic section name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="topicSectionName"
                        name="topicSectionName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <Listbox value={selectedTopic} onChange={setSelectedTopic}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Topic
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {getSelectedTopic(selectedTopic)?.name || "-"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {topics.map((topic) => (
                                  <Listbox.Option
                                    key={topic.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={topic.id}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {topic.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="order"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Order
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        id="order"
                        name="order"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="submissionDeadline"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Submission deadline
                    </label>
                    <div className="mt-2">
                      <input
                        type="datetime-local"
                        id="submissionDeadline"
                        name="submissionDeadline"
                        value={submissionDeadline}
                        onChange={(e) => setSubmissionDeadline(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="submissionDeadline"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Due date
                    </label>
                    <div className="mt-2">
                      <input
                        type="datetime-local"
                        id="submissionDeadline"
                        name="submissionDeadline"
                        value={dueDate}
                        onChange={(e) => setDuedate(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="imageUrl"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Topic section image url
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="imageUrl"
                        name="imageUrl"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="flex h-6 items-center gap-2">
                      <label
                        htmlFor="published"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Published
                      </label>
                      <div>
                        <input
                          type="checkbox"
                          id="published"
                          name="published"
                          checked={published}
                          onChange={(e) => setPublished(e.target.checked)}
                          className="h-4 w-4 rounded border-gray-300 text-[#FFB155] focus:ring-[#FFB155]"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      onClick={onClose}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-[#FFB155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#FFB155] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
