import { FirestoreDataConverter } from "firebase/firestore";
import { Team } from "../../models/Team";

export const teamConverter: FirestoreDataConverter<Team> = {
  toFirestore: (team) => ({
    name: team.name,
    users: team.users,
    sections: team.sections,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      users: data.users,
      sections: data.sections,
    };
  },
};
