//import * as loading from "./loading.json";

export default function LoadingScreen() {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: loading,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {/* <Lottie height={200} width={200} options={defaultOptions} /> */}
    </div>
  );
}
