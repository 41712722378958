import {
  query,
  where,
  getDocs,
  collection,
  documentId,
  doc,
  getDoc,
} from "firebase/firestore";
import { useCallback } from "react";

import { db } from "../db/firebase";
import { Team } from "../models/Team";
import { User } from "../models/User";
import { Score } from "../models/Score";
import { Response } from "../models/Response";
import { useAuth } from "../auth/AuthProvider";
import { ClassSection } from "../models/SchoolClass";
import { teamConverter } from "../db/converters/team";
import { scoreConverter } from "../db/converters/score";
import { responseConverter } from "../db/converters/response";
import { classSectionConverter } from "../db/converters/schoolClass";

class UserNotFoundError extends Error {
  constructor() {
    super("User not found.");
  }
}

export default function useUserService() {
  const { currentUser } = useAuth();

  const getUser = useCallback(async (userId: string): Promise<User> => {
    const userDocRef = doc(db, `users`, userId);

    const userDoc = await getDoc(userDocRef);

    if (userDoc.data() === undefined) throw new UserNotFoundError();

    return {
      id: userDoc.id,
      firstname: userDoc.data()!.firstname,
      lastname: userDoc.data()!.lastname,
      classSections: userDoc.data()!.classSections,
      username: userDoc.data()!.username,
      userType: userDoc.data()!.userType,
    };
  }, []);

  async function getUserTeam(): Promise<Team | null> {
    const teamsCollectionRef = query(
      collection(db, "teams"),
      where("users", "array-contains", currentUser?.id)
    ).withConverter(teamConverter);

    const teamsCollection = await getDocs(teamsCollectionRef);

    if (teamsCollection.docs.length === 0) return null;

    return teamsCollection.docs[0].data();
  }

  async function getUserTeams(): Promise<Team[]> {
    const teamsCollectionRef = query(
      collection(db, "teams"),
      where("users", "array-contains", currentUser?.id)
    ).withConverter(teamConverter);

    const teamsCollection = await getDocs(teamsCollectionRef);

    return teamsCollection.docs.map((doc) => doc.data());
  }

  async function getUserClassSections(): Promise<ClassSection[]> {
    console.log(currentUser);

    if (!currentUser?.classSections.length) return [];

    const classSectionsCollectionRef = query(
      collection(db, "classSections"),
      where(documentId(), "in", currentUser?.classSections)
    ).withConverter(classSectionConverter);

    const classSectionsCollection = await getDocs(classSectionsCollectionRef);

    return classSectionsCollection.docs.map((doc) => ({
      ...doc.data(),
      type: "individual",
    }));
  }

  const getUserQuestionResponse = useCallback(
    async (questionId: string): Promise<Response | null> => {
      const collectionRef = query(
        collection(db, "users", `${currentUser?.id}`, "responses"),
        where("questionId", "==", questionId)
      ).withConverter(responseConverter);

      const result = await getDocs(collectionRef);

      if (result.docs.length === 0) return null;

      return result.docs[0].data();
    },
    [currentUser]
  );

  const getUserQuestionScores = useCallback(
    async (questionId: string): Promise<Score | null> => {
      const collectionRef = query(
        collection(db, "users", `${currentUser?.id}`, "scores"),
        where("questionId", "==", questionId)
      ).withConverter(scoreConverter);

      const result = await getDocs(collectionRef);

      if (result.docs.length === 0) return null;

      return result.docs[0].data();
    },
    [currentUser]
  );

  const getUserResponses = useCallback(
    async (questionsId: string[], userId?: string): Promise<Response[]> => {
      const id = userId || currentUser?.id;
      const collectionRef = query(
        collection(db, "users", `${id}`, "responses"),
        where("questionId", "in", questionsId)
      ).withConverter(responseConverter);

      const result = await getDocs(collectionRef);

      return result.docs.map((doc) => doc.data());
    },
    [currentUser]
  );

  const getUserScores = useCallback(
    async (questionsId: string[], userId?: string): Promise<Score[]> => {
      const id = userId || currentUser?.id;
      const collectionRef = query(
        collection(db, "users", `${id}`, "scores"),
        where("questionId", "in", questionsId)
      ).withConverter(scoreConverter);

      const result = await getDocs(collectionRef);

      return result.docs.map((doc) => doc.data());
    },
    [currentUser]
  );

  return {
    getUser,
    getUserTeam,
    getUserTeams,
    getUserScores,
    getUserResponses,
    getUserClassSections,
    getUserQuestionScores,
    getUserQuestionResponse,
  };
}
