import { omit } from "lodash";
import { doc, addDoc, collection, updateDoc } from "firebase/firestore";

import { db } from "../db/firebase";
import { NewResponse, Response } from "../models/Response";
import { useAuth } from "../auth/AuthProvider";
import { responseConverter } from "../db/converters/response";

export default function useResponseService() {
  const { currentUser } = useAuth();

  async function createResponse(response: NewResponse): Promise<Response> {
    const newResponse = await addDoc(
      collection(db, `users/${currentUser?.id}/responses`).withConverter(
        responseConverter
      ),
      response
    );

    return {
      ...response,
      id: newResponse.id,
    };
  }

  async function updateResponse(
    responseId: string,
    response: Response
  ): Promise<Response> {
    await updateDoc(
      doc(db, "users", currentUser?.id!, "responses", responseId),
      { ...omit(response, "id") }
    );

    return response;
  }

  async function createTeamResponse(
    teamId: string,
    response: NewResponse
  ): Promise<Response> {
    response.username = currentUser?.username;

    const newResponse = await addDoc(
      collection(db, `teams/${teamId}/responses`).withConverter(
        responseConverter
      ),
      response
    );

    return {
      ...response,
      id: newResponse.id,
    };
  }

  async function updateTeamResponse(
    teamId: string,
    responseId: string,
    response: Response
  ): Promise<Response> {
    response.username = currentUser?.username;

    await updateDoc(doc(db, "teams", teamId, "responses", responseId), {
      ...omit(response, "id"),
    });

    return {
      ...response,
      username: currentUser?.username,
    };
  }

  return {
    createResponse,
    updateResponse,
    createTeamResponse,
    updateTeamResponse,
  };
}
