import { useEffect, useState } from "react";

import ContentView from "../../components/ContentView";
import { ClassSection } from "../../models/SchoolClass";
import useUserService from "../../services/useUserService";
import ClassSectionsGrid from "../../components/ClassSectionGrid";

export default function Dashboard() {
  const { getUserClassSections } = useUserService();

  const [classSections, setClassSections] = useState<ClassSection[]>([]);

  useEffect(() => {
    init();

    async function init() {
      const classSections = await getUserClassSections();
      setClassSections(classSections);
    }
  }, [getUserClassSections]);

  return (
    <ContentView title="Class Sections" onBack={() => {}}>
      <ClassSectionsGrid classSections={classSections} />
    </ContentView>
  );
}
