import moment from "moment";
import { useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { TopicSection } from "../../models/Topic";
import { StudentGrading } from "../../models/User";
import ContentView from "../../components/ContentView";
import { ClassSection } from "../../models/SchoolClass";
import useTopicService from "../../services/useTopicService";
import useClassService from "../../services/useClassService";
import useTeacherService from "../../services/useTeacherService";

export default function TeacherStudents() {
  const navigate = useNavigate();
  const { topicSectionId } = useParams<{ topicSectionId: string }>();

  const { getTopic, getTopicSection } = useTopicService();
  const { getClassSection, updateClassSection } = useClassService();
  const { getTeacherStudents, getTeacherStudentsGrading } = useTeacherService();

  const [isGraded, setIsGraded] = useState(false);
  const [students, setStudents] = useState<StudentGrading[]>([]);
  const [topicSection, setTopicSeciton] = useState<TopicSection>();
  const [classSection, setClassSection] = useState<ClassSection | undefined>();

  useEffect(() => {
    init();
    async function init() {
      const topicSection = await getTopicSection(topicSectionId!);
      const topic = await getTopic(topicSection.topicId);
      const classSection = await getClassSection(topic.classSectionId);
      const gradings = await getTeacherStudentsGrading(topicSectionId!);

      setStudents(gradings);
      setTopicSeciton(topicSection);
      setClassSection(classSection);
      setIsGraded(
        classSection.releaseGrades?.includes(topicSection.id) || false
      );
    }
  }, [
    getTopic,
    topicSectionId,
    getTopicSection,
    getClassSection,
    getTeacherStudents,
    getTeacherStudentsGrading,
  ]);

  function getSubmissionStatus(
    isSubmitted: boolean,
    submissionDate?: Timestamp
  ) {
    if (!isSubmitted)
      return (
        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
          Not submitted
        </span>
      );

    if (isSubmitted) {
      if (isSubmissionDueDate(submissionDate!))
        return (
          <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
            Late submitted
          </span>
        );

      return (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Submitted
        </span>
      );
    }
  }

  function isSubmissionDueDate(submissionDate: Timestamp): boolean {
    const submission = moment(submissionDate.toDate());
    const submissionDeadline = moment(
      topicSection?.submissionDeadline.toDate()
    );

    return submission.isAfter(submissionDeadline);
  }

  async function releaseGrades() {
    if (classSection) {
      if (classSection.releaseGrades) {
        classSection.releaseGrades.push(topicSectionId!);
      } else {
        classSection.releaseGrades = [];
        classSection.releaseGrades.push(topicSectionId!);
      }

      await updateClassSection(classSection.id, classSection);

      setClassSection({
        ...classSection,
      });
    }
  }

  return (
    <ContentView
      title="Gradings - Students"
      onBack={() => navigate(-1)}
      customContent={
        <span
          onClick={() => (!isGraded ? releaseGrades() : null)}
          className={`${
            !isGraded ? "cursor-pointer text-[#FFB155]" : "text-gray-300"
          } flex items-center font-[Semibold] text-sm gap-3 mr-56`}
        >
          Release grades
        </span>
      }
    >
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-[Semibold] text-gray-900 sm:pl-6"
              >
                First Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-[Semibold] text-gray-900"
              >
                Last Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Score
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {students
              .sort((studentA, studentB) =>
                studentA.lastname > studentB.lastname ? 1 : -1
              )
              .map((student) => (
                <tr key={student.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-[Regular] text-gray-900 sm:pl-6">
                    {student.firstname}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-[Regular]">
                    {student.lastname}
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    {getSubmissionStatus(
                      student.isTopicSubmitted,
                      student.submissionDate
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-[Regular]">
                    -
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 font-[Regular]">
                    <span
                      onClick={() => navigate(`student/${student.id}`)}
                      className="text-[#FFB155] hover:text-[#FFB155] cursor-pointer"
                    >
                      Grade
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </ContentView>
  );
}
