import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import Topics from "./Topics";
import Classes from "./Classes";
import Dashboard from "./Dashboard";
import Questions from "./Questions";
import ClassSections from "./ClassSections";
import RequireAuth from "../../auth/RequireAuth";
import TopicSections from "./Topics/TopicSections";
import Activities from "./Activities";
import Teachers from "./Teachers";

export default function AdminPanel() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      >
        <Route path="/topics" element={<Topics />} />
        <Route path="/classes" element={<Classes />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/admin-panel" element={<Dashboard />} />
        <Route path="/class-sections" element={<ClassSections />} />
        <Route path="/topic-sections" element={<TopicSections />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/teachers" element={<Teachers />} />
      </Route>
    </Routes>
  );
}
