import { ActivityTypesProps } from "../Activities";

interface FullImageActivityProps extends ActivityTypesProps {}

export default function FullImageActivity({
  onBack,
  onNext,
  activity,
}: FullImageActivityProps) {
  return (
    <div className="h-full flex flex-col">
      <div className="h-full">
        <img
          src={activity.heroImageUrl}
          alt=""
          className="h-full w-full object-cover"
        />
      </div>
      <div className="flex items-center justify-between mt-auto pt-6">
        <button
          className="font-['Semibold'] text-base border border-[#E0E0EC] rounded-full px-10 py-4 leading-5"
          onClick={onBack}
        >
          Back
        </button>
        <button
          onClick={() => onNext()}
          className="font-['Semibold'] text-base bg-[#FFB155] rounded-full px-10 py-4 leading-5"
        >
          Next
        </button>
      </div>
    </div>
  );
}
