import { FirestoreDataConverter } from "firebase/firestore";
import { Question } from "../../models/Question";

export const questionConverter: FirestoreDataConverter<Question> = {
  toFirestore: (question) => ({
    title: question.title,
    activityId: question.activityId,
    order: question.order,
    questionType: question.questionType,
    correctAnswer: question.correctAnswer,
    defaultPoints: question.defaultPoints,
    options: question.options,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
      order: data.order,
      points: data.points,
      options: data.options,
      activityId: data.activityId,
      questionType: data.questionType,
      correctAnswer: data.correctAnswer,
      defaultPoints: data.defaultPoints,
    };
  },
};
